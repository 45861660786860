import { Component, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { Subject, take, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { FormArray, FormArrayName, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import * as CryptoJS from 'crypto-js';
import { Router } from "@angular/router";
import { AddProduct } from "@/model/medical-entry/add-product/add-product";
import { addVendor } from "@/model/addVendor";

@Component({
  selector: "app-product-entry-form",
  templateUrl: "./product-entry-form.component.html",
  styleUrls: ["./product-entry-form.component.scss"]
})
export class ProductEntryFormComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  getProductDetails : AddProduct [] = [];
  variantErrors : any[] = [];
  getVendorDetails: addVendor [] = [];
  isInvoiceAlreadyExist: boolean = false;
  gstDetails: any;
  totalBillValue: number = 0;

  constructor(private appServices: AppService, private datePipe: DatePipe, private toastr: ToastrService,
    private spinner: NgxSpinnerService,private router: Router) {
  }

  productEntryFrom = new UntypedFormGroup({
    productId: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    vendorId: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    vendorName : new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    invoiceNo : new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    invoiceAmount : new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    date : new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    productInventoryDetails  : new FormArray([]),
  })

  public productEntryFromError = {
    vendorName: "",
    invoiceNo: "",
    invoiceAmount: "",
    date: "",
  }

  get productInventoryDetails() {
    return this.productEntryFrom.get('productInventoryDetails') as FormArray;
  }



  ngOnInit(): void {
      this.getAllProductDetails();
      this.getAllVendorDetails();
  }

    // Add New Variant
    addVariant() {
      const variantGroup = new UntypedFormGroup({
        productName : new UntypedFormControl("", [Validators.required]),
        productId : new UntypedFormControl("", [Validators.required]),
        batchCode : new UntypedFormControl("", [Validators.required]),
        hsnCode: new UntypedFormControl("", [Validators.required]),
        pack:new UntypedFormControl("", [Validators.required]),
        expiryDate : new UntypedFormControl("", [Validators.required,Validators.pattern(/^(0[1-9]|1[0-2])\/\d{2}$/)]),
        mrpRate : new UntypedFormControl("", [Validators.required]),
        purchaseQty : new UntypedFormControl("", [Validators.required]),
        unitPrice : new UntypedFormControl("", [Validators.required]),
        taxAmount : new UntypedFormControl("", [Validators.required]),
        totalPrice : new UntypedFormControl("", [Validators.required]),
        discount : new UntypedFormControl("", [Validators.required]),
        cgst: new UntypedFormControl("", [Validators.required]),
        sgst: new UntypedFormControl("", [Validators.required]),
        igst: new UntypedFormControl("", [Validators.required]),
      });

      this.productInventoryDetails.push(variantGroup);
    }

 // Remove Variant
 removeVariant(index: number) {
  if(index > 0) {
  this.productInventoryDetails.removeAt(index);
  }else {
    this.toastr.error('Cannot remove the first variant.');
  }
}

  getAllProductDetails(){
    this.spinner.show();
    this.appServices.getAllProduceDetails()
     .pipe(takeUntil(this.destroy$))
     .subscribe((res: any) => {
      this.spinner.hide();
      console.log(res);
      this.getProductDetails = res || [];
      this.getProductDetails.sort(((a, b) => a.name.localeCompare(b.name)));
      this.addVariant();
     }, (err: any) => {
      this.spinner.hide();
      this.toastr.error( err.error.message || "Something went wrong, Please try again later....");
     });
  }

  getAllVendorDetails(){
    this.spinner.show();
    this.appServices.getAllVendorDetailsByNew()
    .pipe(takeUntil(this.destroy$))
    .subscribe((res: any) => {
      this.spinner.hide();
      this.getVendorDetails = res ;
      this.getVendorDetails.sort((a, b) =>
        a.vendorName.localeCompare(b.vendorName)
      );
    }, (err: any) => {
      this.spinner.hide();
      this.toastr.error( err.error.message || "Something went wrong, Please try again later....");
    });
  }

  dateFormatted(event: any) {
    const dateFromNumber = event.target.valueAsNumber;
    this.productEntryFrom.controls['date'].setValue(this.datePipe.transform(dateFromNumber, 'yyyy-MM-dd'));
  }

  onSubmit() {
    this.formValidation()
  }


  formValidation() {
    this.productEntryFromError.vendorName = "";
    this.productEntryFromError.invoiceNo = "";
    this.productEntryFromError.invoiceAmount = "";
    this.productEntryFromError.date = "";
    let hasError = false;

    if (this.productEntryFrom.get('vendorName')?.invalid) {
      this.productEntryFromError.vendorName = "*Vendor Name is Required";
      hasError = true;
    }

    if (this.productEntryFrom.get('invoiceNo')?.invalid) {
      this.productEntryFromError.invoiceNo = "*Invoice No is Required";
      hasError = true;
    }

    if (this.productEntryFrom.get('invoiceAmount')?.invalid) {
      this.productEntryFromError.invoiceAmount = "*Invoice Amount is Required";
      hasError = true;
    }

    if (this.productEntryFrom.get('date')?.invalid) {
      this.productEntryFromError.date = "*Date is Required";
      hasError = true;
    }


  // Validate Product Variants
  if (this.productInventoryDetails.length === 0) {
    this.toastr.error("At least one product variant is required.");
    hasError = true;
  } else {
    this.productInventoryDetails.controls.forEach((variant, index) => {
      let variantError: any = {};

      if (variant.get('productName')?.invalid) {
        variantError.productName = "*Product Name is required";
      }

      if (variant.get('batchCode')?.invalid) {
        variantError.batchCode = "*Batch Code is required";
      }

      if (variant.get('hsnCode')?.invalid) {
        variantError.hsnCode = "*HSN/SAC Code is required";
      }

      if (variant.get('expiryDate')?.invalid) {
        variantError.expiryDate = "*Expiry Date is required";
      }

      if (variant.get('mrpRate')?.invalid || variant.get('mrpRate').value == 0) {
        variantError.mrpRate = "*MRP Rate must be at least 1";
      }

      if (variant.get('purchaseQty')?.invalid || variant.get('purchaseQty').value == 0) {
        variantError.purchaseQty = "*Purchase Quantity must be at least 1";
      }

      if (variant.get('unitPrice')?.invalid || variant.get('unitPrice').value == 0) {
        variantError.unitPrice = "*Unit Price must be at least 1";
      }

      if (variant.get('totalPrice')?.invalid || variant.get('totalPrice').value == 0) {
        variantError.totalPrice = "*Total Amount must be at least 1";
      }


      if (Object.keys(variantError).length > 0) {
        this.variantErrors[index] = variantError;
        hasError = true;
      } else {
        this.variantErrors[index] = {};
      }
    });
  }

    if (!hasError) {
      if (this.isInvoiceAlreadyExist) {
        this.productEntryFromError.invoiceNo = "Invoice No already exists";
        return;
      }

      const totalAmount = this.productEntryFrom.get('invoiceAmount')?.value;
      if (this.totalBillValue === totalAmount) {
        this.saveProductInventory();
      } else {
        this.toastr.info("Invoice amount and total amount do not match");
      }
    }

  }

  selectVendorName(event : any){
    const inputElement = event.target as HTMLInputElement;
    const name = inputElement.value.trim(); // Trim to avoid spaces affecting comparison

    // Find the vendor ID based on the entered vendor name
    const selectedVendor = this.getVendorDetails.find(item => item.vendorName === name);

    if (selectedVendor) {
      this.productEntryFrom.controls['vendorId'].setValue(selectedVendor.vendorId);
      this.productEntryFromError.vendorName = ""; // Clear validation error
    } else {
      this.productEntryFrom.controls['vendorId'].setValue(null);
      this.productEntryFromError.vendorName = "*Invalid vendor selected";
    }

    // Only check the invoice if a valid vendor is selected
    if (selectedVendor) {
      this.checkInvoiceBillNo();
    }
  }

  selectCurrentProductName(index: number) {
    const group = (this.productInventoryDetails as FormArray).controls[index] as FormGroup;
    const productName = group.get('productName')?.value.trim(); // Trim spaces to avoid mismatch

    // Find the selected product by name
    const product = this.getProductDetails.find(item => item.name === productName);

    if (product) {
      group.get('productId')?.setValue(product.productId, { emitEvent: false });
      this.variantErrors[index].productName = ""; // Clear error message if valid
    } else {
      group.get('productId')?.setValue(null);
      this.variantErrors[index].productName = "*Invalid product selected"; // Show error if not found
    }
  }


  updateTotalPrice() {
    (this.productInventoryDetails as FormArray).controls.forEach((group: FormGroup, index: number) => {
      const purchaseQty = group.get('purchaseQty')?.value || 0;
      const unitPrice = group.get('unitPrice')?.value || 0;

      // Calculate total price (Purchase Qty * Unit Price)
      const totalPrice = purchaseQty * unitPrice;

      // Update the totalPrice field without triggering another event
      group.get('totalPrice')?.setValue(totalPrice, { emitEvent: false });
      this.totalBillValue += totalPrice;
    });
  }


  checkInvoiceBillNo(){
    const jsonData = {
      "invoiceNo": this.productEntryFrom.value.invoiceNo,
      "vendorName": this.productEntryFrom.value.vendorName
    }
    this.appServices.checkVendorInvoiceBillNo(jsonData)
    .pipe(takeUntil(this.destroy$))
    .subscribe((data: any) => {
      if (data.statusCode == 200) {
        this.productEntryFromError.invoiceNo = "";
        this.isInvoiceAlreadyExist = false;
      }
    }, (err: any) => {
      if (err.error.statusCode == 409) {
        this.isInvoiceAlreadyExist = true;
        this.productEntryFromError.invoiceNo = "*Invoice No is already exists";
      }
    })
  }




  formatExpiryDate(event: any, i: number) {
    let inputValue = event.target.value.replace(/\D/g, '');
    if (inputValue.length > 2) {
      inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(2, 4)}`;
    }
    event.target.value = inputValue;

    // Set formatted value in the corresponding form control
    const control = (this.productInventoryDetails as FormArray).at(i).get('expiryDate');
    if (control) {
      control.setValue(inputValue, { emitEvent: false });
    }

  }



  saveProductInventory(){
    this.spinner.show();
    this.appServices.saveProductInventoryDetails(this.productEntryFrom.value)
     .pipe(takeUntil(this.destroy$))
     .subscribe((res: any) => {
      this.toastr.success("Product Inventory Saved Successfully.");
      this.spinner.hide();
      window.location.reload();
     }, (err: any) => {
      this.spinner.hide();
      this.toastr.error(err.error.message || "Something went wrong, Please try again later....");
     });
  }


  //Get HSN code based gst details
  getGSTDetails() {
    (this.productInventoryDetails as FormArray).controls.forEach((group: FormGroup, index: number) => {
      const hsnCode = group.get('hsnCode')?.value || 0;
      this.appServices.getGSTDetailsByHSNCode(hsnCode)
        .pipe(takeUntil(this.destroy$))
        .subscribe((hsnCode: any) => {
          this.gstDetails = hsnCode;
          group.patchValue({
            cgst: this.gstDetails.cgst || 0,
            sgst: this.gstDetails.sgst || 0,
            igst: this.gstDetails.igst || 0,
          });
          console.log(`gst data::`, this.gstDetails);
        }, (err: any) => {
          this.spinner.hide();
         /* this.toastr.error(err.error.message || "Something went wrong, Please try again later....");*/
        });
    });

  }

}
