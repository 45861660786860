import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from '@/app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from '@modules/main/main.component';
import { LoginComponent } from '@modules/login/login.component';
import { HeaderComponent } from '@modules/main/header/header.component';
import { FooterComponent } from '@modules/main/footer/footer.component';
import { MenuSidebarComponent } from '@modules/main/menu-sidebar/menu-sidebar.component';
import { BlankComponent } from '@pages/blank/blank.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ProfileComponent } from '@pages/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterComponent } from '@modules/register/register.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { MessagesComponent } from '@modules/main/header/messages/messages.component';
import { NotificationsComponent } from '@modules/main/header/notifications/notifications.component';

import { DatePipe, registerLocaleData } from "@angular/common";
import localeEn from '@angular/common/locales/en';
import { UserComponent } from '@modules/main/header/user/user.component';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { LanguageComponent } from '@modules/main/header/language/language.component';
import { MainMenuComponent } from './pages/main-menu/main-menu.component';
import { SubMenuComponent } from './pages/main-menu/sub-menu/sub-menu.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { ControlSidebarComponent } from './modules/main/control-sidebar/control-sidebar.component';
import { StoreModule } from '@ngrx/store';
import { authReducer } from './store/auth/reducer';
import { uiReducer } from './store/ui/reducer';
import { ProfabricComponentsModule } from '@profabric/angular-components';
import { defineCustomElements } from '@profabric/web-components/loader';
import { SidebarSearchComponent } from './components/sidebar-search/sidebar-search.component';
import { NewJoiningFormsComponent } from "./new-joining-forms/new-joining-forms.component";
import { FileuploadComponent } from "@/fileupload/fileupload.component";
import { TrainingcertificateComponent } from "@/training-certificate/training-certificate.component";
import { PatientCountDetailsComponent } from "@/patient-count-details/patient-count-details.component";
import { StockListComponent } from "@/stock-list/stocklist.component";
import { StockUploadComponent } from "@/stockUpload/stockupload.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { NgxSpinnerModule } from "ngx-spinner";
import { AmddsHeaderComponent } from "@/amdds-header/amdds-header.component";
import { AmddsFooterComponent } from "@/amdds-footer/amdds-footer.component";
import { AmddsDashboardComponent } from "@/amdds-dashboard/amdds-dashboard.component";
import { OldVoiceComponent } from './vendorDetails/old-voice/old-invoice.component';
import { VendorPaymentComponent } from './vendorDetails/vendorPayment/vendorPayment.component';
import { AddVendorComponent } from './vendorDetails/addVendor/addVendor.component';
import { NewJoiningFormReportComponent } from './new-joining-form-report/new-joining-form-report.component';
import { printDataService } from './model/printDataService';
import { SameStockListComponent } from './same-stock-list/same-stock-list.component';
import { HospitalVendorDetailsComponent } from './vendorDetails/hospital-vendor-details/hospital-vendor-details.component';
import { VendorNavbarComponent } from './vendorDetails/vendor-navbar/vendor-navbar.component';
import { OverAllVendorPaymentComponent } from './vendorDetails/overall-vendor-payment-details/overall-vendor-payment-details.component';
import { StockExpiryListComponent } from './stock-expiry-list/stock-expiry-list.component';
import { ScanCenterFormComponent } from './scan-center/scan-center-form/scan-center-form.component';
import { ScanCenterPaymentComponent } from './scan-center/scan-center-payments/scan-center-payment.component';
import { ScanCenterDoctorPaymentComponent } from './scan-center/scan-center-doctor-payment/scan-center-doctor-payment.component';
import { LabTestFormComponent } from './lab-test/lab-test-form/lab-test-form.component';
import { LabTestViewComponent } from './lab-test/lab-test-view/lab-test-view.component';
import { LabReceiptPrintComponent } from './lab-test/lab-receipt-print/lab-receipt-print.component';
import { ScanCenterReceiptPrintComponent } from './scan-center/scan-center-receipt-print/scan-center-receipt-print.component';
import { AddProductComponent } from '@pages/medical-entry/add-product/add-product.component';
import { ProductEntryFormComponent } from '@pages/medical-entry/product-entry/product-entry-form.component';
import { ConfigLoader } from '@services/config-service/config.loader';
import { ConfigService } from '@services/config-service/config-service';
import { ProductReportTableComponent } from '@pages/medical-entry/product-report/product-report-table.component';
import { StockAdjustmentComponent } from '@pages/medical-entry/stock-adjustment-screen/stock-adjustment-form.component';
import {MargComponent} from "@/marg/marg.component";


defineCustomElements();
registerLocaleData(localeEn, 'en-EN');

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    BlankComponent,
    ProfileComponent,
    RegisterComponent,
    DashboardComponent,
    MessagesComponent,
    NotificationsComponent,
    UserComponent,
    ForgotPasswordComponent,
    RecoverPasswordComponent,
    LanguageComponent,
    MainMenuComponent,
    SubMenuComponent,
    MenuItemComponent,
    ControlSidebarComponent,
    SidebarSearchComponent,
    AmddsDashboardComponent,
    NewJoiningFormsComponent,
    NewJoiningFormReportComponent,
    FileuploadComponent,
    TrainingcertificateComponent,
    PatientCountDetailsComponent,
    StockListComponent,
    StockUploadComponent,
    AmddsHeaderComponent,
    AmddsFooterComponent,
    OldVoiceComponent,
    VendorPaymentComponent,
    AddVendorComponent,
    SameStockListComponent,
    HospitalVendorDetailsComponent,
    VendorNavbarComponent,
    OverAllVendorPaymentComponent,
    StockExpiryListComponent,
    ScanCenterFormComponent,
    ScanCenterPaymentComponent,
    ScanCenterDoctorPaymentComponent,
    LabTestFormComponent,
    LabTestViewComponent,
    LabReceiptPrintComponent,
    ScanCenterReceiptPrintComponent,
    AddProductComponent,
    ProductEntryFormComponent,
    ProductReportTableComponent,
    StockAdjustmentComponent,
    MargComponent
  ],
  imports: [
    BrowserModule,
    StoreModule.forRoot({ auth: authReducer, ui: uiReducer }),
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-right",
      preventDuplicates: true
    }),
    ProfabricComponentsModule,
    Ng2SearchPipeModule,
    FormsModule,
    NgxSpinnerModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoader,
      deps: [ConfigService],
      multi: true,
    },
    printDataService,
    DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule {
}
