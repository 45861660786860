import { Component, HostBinding, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntil, Subject } from "rxjs";
import { addVendor } from '@/model/addVendor';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-addVendor',
  templateUrl: './addVendor.component.html',
  styleUrls: ['./addVendor.component.scss']
})
export class AddVendorComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  vendorDetails: addVendor[] = [];
  addVendor: addVendor | undefined;
  vendorId: number = 0;
  isUpdate: boolean = false;
  getVendorDetails: addVendor;
  isVendorNameExist: boolean = false

  constructor(private appservice: AppService, private toastr: ToastrService, private spinner: NgxSpinnerService,
    private router: Router, private activatedRoute: ActivatedRoute) {
    this.newvendor.get('vendorName')?.valueChanges.subscribe(value => {
      if (value) {
        this.newvendor.patchValue({ vendorName: value.toUpperCase() }, { emitEvent: false });
      }
    });
  }
  newvendor = new UntypedFormGroup(
    {
      vendorName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      mobileNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      city: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      gstNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      bankName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      bankAccountNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      accHolderName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      branch: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      bankIfscCode: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      vendorId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    }
  );
  public NewVendorErrors = {
    vendorName: "",
    mobileNo: "",
    city: "",
    gstNo: "",
    bankName: "",
    bankAccountNo: "",
    accHolderName: "",
    branch: "",
    bankIfscCode: "",
  };
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      const encodedId = params['id'];
      if (encodedId) {
        this.vendorId = +atob(encodedId);
        console.log("productId", this.vendorId);
      }
    });
    if (this.vendorId !== 0) {
      this.isUpdate = true;
      this.vendorDetailsGetById(this.vendorId);
    } else {
      this.isUpdate = false;
    }
  }
  onSubmit() {
    this.formvalidation();
  }
  formvalidation() {
    this.NewVendorErrors.vendorName = "";
    this.NewVendorErrors.mobileNo = "";
    this.NewVendorErrors.city = "";
    this.NewVendorErrors.gstNo = "";
    this.NewVendorErrors.bankName = "";
    this.NewVendorErrors.bankAccountNo = "";
    this.NewVendorErrors.accHolderName = "";
    this.NewVendorErrors.branch = "";
    this.NewVendorErrors.bankIfscCode = "";
    let hasError = false;

    if (this.newvendor.get("vendorName")?.invalid) {
      this.NewVendorErrors.vendorName = "*Vendor Name is Required";
      hasError = true;
    }
    if (this.newvendor.get("mobileNo")?.invalid) {
      if (this.newvendor.get("mobileNo")?.errors?.["required"]) {
        this.NewVendorErrors.mobileNo = "*Mobile Number is required";
      }
      if (this.newvendor.get("mobileNo")?.getError("pattern")) {
        this.NewVendorErrors.mobileNo = "*Enter a 10-digit Mobile number.";
      }
      hasError = true;
    }
    if (this.newvendor.get("city")?.invalid) {
      this.NewVendorErrors.city = "*City is Required";
      hasError = true;
    }
    if (this.newvendor.get("gstNo")?.invalid) {
      this.NewVendorErrors.gstNo = "*GST is Required";
      hasError = true;
    }
    if (this.newvendor.get("bankName")?.invalid) {
      this.NewVendorErrors.bankName = "*Bank Name is Required";
      hasError = true;
    }
    if (this.newvendor.get("bankAccountNo")?.invalid) {
      this.NewVendorErrors.bankAccountNo = "*Account Number is Required";
      hasError = true;
    }
    if (this.newvendor.get("accHolderName")?.invalid) {
      this.NewVendorErrors.accHolderName = "*Account Holder Name is Required";
      hasError = true;
    }
    if (this.newvendor.get("branch")?.invalid) {
      this.NewVendorErrors.branch = "*Branch Name is Required";
      hasError = true;
    }
    if (this.newvendor.get("bankIfscCode")?.invalid) {
      this.NewVendorErrors.bankIfscCode = "*IFSC Code is Required";
      hasError = true;
    }

    if (!hasError) {
      if (this.isUpdate == true) {
        if (this.isVendorNameExist == true && this.newvendor.get("vendorName").value != this.getVendorDetails.vendorName) {
          this.NewVendorErrors.vendorName = "Vendor Name is already exists"
          return;
        } else if (this.newvendor.get("vendorName").value == (this.getVendorDetails.vendorName)) {
          this.NewVendorErrors.vendorName = ""
          this.isVendorNameExist = false;
          return
        } else {
          this.updateVendorDetailsNew();
        }
      } else {
        if (this.isVendorNameExist == true) {
          this.NewVendorErrors.vendorName = "Vendor Name is already exists"
          return;
        } else {
          this.saveVendorDetails();
        }
      }
    }


  }
  checkVendorName(name: string): void {
    const vendorNameNew = name
    this.newvendor.patchValue({ vendorNameNew });
    this.vendorNameCheck();
  }



  //Vendor Name check function
  vendorNameCheck() {
    this.appservice.vendorNameExistingCheck(this.newvendor.get('vendorName')?.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data.statusCode == 200) {
          this.NewVendorErrors.vendorName = "";
          this.isVendorNameExist = false;
        }
      }, (err: any) => {
        if (err.error.statusCode == 409) {
          this.isVendorNameExist = true;
          this.NewVendorErrors.vendorName = "*Vendor Name is already exists";
        }
      })
  }

  //Old Save function
  saveVendor() {
    this.spinner.show();
    this.appservice
      .saveVendor(this.newvendor.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // @ts-ignore
        this.addVendor = data;
        this.spinner.hide();
        this.toastr.success("Vendor Details Saved Successfully")
        this.newvendor.reset()
      }, (err: HttpErrorResponse) => {
        this.spinner.hide();
        var localerror: string = ""
        localerror = err.error.message || 'An error occurred'
        if (localerror == "already exists") {
          this.toastr.error(err.error.message || 'An error occurred', 'Error');
        } else {
          console.log("Something Went Wrong")
          this.toastr.error("Something went wrong , Please try again later....")
        }
      })
  }

  //New Vendor personal & bank details save functionality
  saveVendorDetails() {
    this.spinner.show();
    this.appservice.saveVendorDetails(this.newvendor.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.addVendor = data;
        this.spinner.hide();
        this.toastr.success("Vendor Details Updated Successfully")
        this.newvendor.reset()
      }, (err: HttpErrorResponse) => {
        this.spinner.hide();
        var localerror: string = ""
        localerror = err.error.message || 'An error occurred'
        if (localerror == "already exists") {
          this.toastr.error(err.error.message || 'An error occurred', 'Error');
        } else {
          console.log("Something Went Wrong")
          this.toastr.error("Something went wrong , Please try again later....")
        }
      })
  }

  //only allowed number function
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  //Focus on the next input element
  focusNext(event: KeyboardEvent, nextElementId: string) {
    if (event.key === 'Enter') {
      event.preventDefault();
      const nextElement = document.getElementById(nextElementId);
      if (nextElement) {
        nextElement.focus();
      }
    }
  }

  //get vendor details by id function  old vendor details update function
  getVendorIdDetails(vendorId: number) {
    this.spinner.show();
    this.appservice.getVendorIdDetails(vendorId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log(data);
        this.getVendorDetails = data;
        this.newvendor.patchValue(data);
        console.log(this.newvendor.value)
        this.spinner.hide();
      }, (err: any) => {
        this.toastr.error("Something went wrong , Please try again later....")
        this.spinner.hide();

      })
  }

  //get vendor details by id function new vendor details update function
  vendorDetailsGetById(vendorId: number) {
    this.spinner.show();
    this.appservice.vendorDetailsGetById(vendorId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log(data);
        this.getVendorDetails = data;
        this.newvendor.patchValue(data);
        console.log(this.newvendor.value)
        this.spinner.hide();
      }, (err: any) => {
        this.toastr.error("Something went wrong , Please try again later....")
        this.spinner.hide();

      })
  }


  //Old vendor details update function  
  updateVendorDetails() {
    this.spinner.show();
    this.newvendor.controls['vendorId'].setValue(this.vendorId);
    console.log(this.newvendor.value);
    this.appservice.updateVendorDetails(this.newvendor.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        console.log(data);
        this.toastr.success("Vendor Details Updated Successfully");
        this.router.navigate(['/HospitalVendor']);
      }, (err: any) => {
        this.spinner.hide();
        this.toastr.error(err.message || "Something went wrong, please try again....");
      })

  }

  //New vendor personal & bank account details update functionality
  updateVendorDetailsNew() {
    this.spinner.show();
    this.newvendor.controls['vendorId'].setValue(this.vendorId);
    console.log(this.newvendor.value);
    this.appservice.updateVendorBankDetails(this.newvendor.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        console.log(data);
        this.toastr.success("Vendor Details Updated Successfully");
        this.router.navigate(['/HospitalVendor']);
      }, (err: any) => {
        this.spinner.hide();
        this.toastr.error(err.message || "Something went wrong, please try again....");
      })
  }

}