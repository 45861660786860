<app-amdds-header></app-amdds-header>

<nav class="navbar navbar-expand-lg navbar-light mt-5" style="background-color:darkgray">
  <div class="container-fluid">
    <a class="navbar-brand">Lab Test Details</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mx-4 topnav" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto ">
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/lab-test-form"><b>Lab Test Entry</b></a>
        </li>
        <li class="nav-item" *ngIf="this.userType != 'LAB'">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/lab-test-view"><b>Lab Test Details View</b></a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="container-fluid">
  <div class="mt-2"></div>
  <!-- <h3 class="ml-3 mt-4">Stock Upload</h3> -->
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card">
        <form (ngSubmit)="onSubmit()" [formGroup]="addLabTestDetails">
          <div class="card-body">
            <div class="row py-2">
              <div class="col-md-3">
                <div class="form-floating">
                  <input type="text" formControlName="patientName" class="form-control" placeholder="Patient Name">
                  <label>Patient Name <span style="color:red;">*</span></label>
                </div>
                <span *ngIf="addLabTestDetailsError.patientName" class="text-danger">{{ addLabTestDetailsError.patientName }}</span>
              </div>
              <div class="col-md-3">
                <div class="form-floating">
                  <input type="tel" formControlName="age" class="form-control" placeholder="Patient Age" maxlength="3" (keypress)="numberOnly($event)">
                  <label>Patient Age <span style="color:red;">*</span></label>
                </div>
                <span *ngIf="addLabTestDetailsError.age" class="text-danger">{{ addLabTestDetailsError.age }}</span>
              </div>
              <div class="col-md-3">
                <div class="form-floating">
                  <select class="form-control" id="employee_joining" formControlName="gender">
                    <option value="" selected>Select A Patient Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                  <label>Patient Gender <span style="color:red;">*</span></label>
                </div>
                <span *ngIf="addLabTestDetailsError.gender" class="text-danger">{{ addLabTestDetailsError.gender }}</span>
              </div>
              <div class="col-md-3">
                <div class="form-floating">
                  <input type="tel" formControlName="phoneNumber" class="form-control" placeholder="Phone Number" maxlength="10" (keypress)="numberOnly($event)">
                  <label>Phone Number<span style="color:red;">*</span></label>
                </div>
                <span *ngIf="addLabTestDetailsError.phoneNumber" class="text-danger">{{ addLabTestDetailsError.phoneNumber }}</span>
              </div>
            </div>
      
            <!-- Multi-Select Dropdown with Checkboxes -->
            <div class="row py-2">

                <div class="col-md-6 card">
                  <label class="form-label">Select Tests</label>
                  <div class="card"   style="height: 200px;overflow-y: scroll;">
                  <div *ngFor="let test of testList">
                    <div class="custom-checkbox">
                      <input type="checkbox" class="ms-2" [value]="test.name" (change)="onCheckboxChange($event, test)" 
                      [checked]="selectedTests.includes(test.name)">
                      <label class="ms-1">{{ test.name }} - ₹{{ test.price }}</label>
                    </div>
                  </div>
                </div>

                </div>
                <div class="col-md-6">
                  <div class="row">
                <div class="col-md-6">
                  <div class="form-floating">
                  <input type="text" class="form-control" formControlName="amount" readonly>
                  <label for="totalAmount" class="form-label">Total Amount <span style="color:red;">*</span></label>
                  <span *ngIf="addLabTestDetailsError.amount" class="text-danger">{{ addLabTestDetailsError.amount }}</span>
                </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating">
                    <select class="form-control" id="referenceDoctor" formControlName="referenceDoctor">
                      <option value="" selected>Select A Reference Doctor</option>
                      <option *ngFor="let doctor of this.doctorName" [value]="doctor.doctorName">{{ doctor.doctorName }}</option>
                    </select>
                    <label>Reference Doctor </label>
                  </div>
                </div>
                
              </div>
              <br>
              <div class="col-md-12">
                <div class="form-floating">
                  <input type="datetime-local"  formControlName="reportreadyDateandtime" class="form-control" placeholder="Report Ready Date and time">
                  <label for="Report Ready Date and time">Report Ready Date and time <span style="color:red;">*</span></label>
                  <span *ngIf="addLabTestDetailsError.reportreadyDateandtime" class="text-danger">{{ addLabTestDetailsError.reportreadyDateandtime }}</span>
                </div>
              </div>
              </div>
            </div>

              <div class="row py-2">
              </div>


            <!-- Submit Button -->
            <div class="row text-center">
              <div class="col-md-12">
                <button type="submit" class="btn btn-primary btn-lg">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    </div>
    </div>
<ngx-spinner>
  <div class="loader">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>

<app-amdds-footer></app-amdds-footer>
