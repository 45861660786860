import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from '@modules/main/main.component';
import { BlankComponent } from '@pages/blank/blank.component';
import { LoginComponent } from '@modules/login/login.component';
import { ProfileComponent } from '@pages/profile/profile.component';
import { RegisterComponent } from '@modules/register/register.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { AuthGuard } from '@guards/auth.guard';
import { NonAuthGuard } from '@guards/non-auth.guard';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { SubMenuComponent } from '@pages/main-menu/sub-menu/sub-menu.component';
import { AmddsDashboardComponent } from "@/amdds-dashboard/amdds-dashboard.component";
import { NewJoiningFormsComponent } from "./new-joining-forms/new-joining-forms.component";
import { NewJoiningFormReportComponent } from "./new-joining-form-report/new-joining-form-report.component";
import { FileuploadComponent } from "@/fileupload/fileupload.component";
import { TrainingcertificateComponent } from "@/training-certificate/training-certificate.component";
import { PatientCountDetailsComponent } from "@/patient-count-details/patient-count-details.component";
import { StockUploadComponent } from "@/stockUpload/stockupload.component";
import { StockListComponent } from "@/stock-list/stocklist.component";
import { OldVoiceComponent } from './vendorDetails/old-voice/old-invoice.component';
import { VendorPaymentComponent } from './vendorDetails/vendorPayment/vendorPayment.component';
import { AddVendorComponent } from './vendorDetails/addVendor/addVendor.component';
import { SameStockListComponent } from './same-stock-list/same-stock-list.component';
import { HospitalVendorDetailsComponent } from './vendorDetails/hospital-vendor-details/hospital-vendor-details.component';
import { OverAllVendorPaymentComponent } from './vendorDetails/overall-vendor-payment-details/overall-vendor-payment-details.component';
import { StockExpiryListComponent } from './stock-expiry-list/stock-expiry-list.component';
import { ScanCenterFormComponent } from './scan-center/scan-center-form/scan-center-form.component';
import { ScanCenterPaymentComponent } from './scan-center/scan-center-payments/scan-center-payment.component';
import { ScanCenterDoctorPaymentComponent } from './scan-center/scan-center-doctor-payment/scan-center-doctor-payment.component';
import { LabTestFormComponent } from './lab-test/lab-test-form/lab-test-form.component';
import { LabTestViewComponent } from './lab-test/lab-test-view/lab-test-view.component';
import { LabReceiptPrintComponent } from './lab-test/lab-receipt-print/lab-receipt-print.component';
import { ScanCenterReceiptPrintComponent } from './scan-center/scan-center-receipt-print/scan-center-receipt-print.component';
import { AddProductComponent } from '@pages/medical-entry/add-product/add-product.component';
import { ProductEntryFormComponent } from '@pages/medical-entry/product-entry/product-entry-form.component';
import { ProductReportTableComponent } from '@pages/medical-entry/product-report/product-report-table.component';
import { StockAdjustmentComponent } from '@pages/medical-entry/stock-adjustment-screen/stock-adjustment-form.component';
import {MargComponent} from "@/marg/marg.component";

const routes: Routes = [
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'blank',
        component: BlankComponent
      },
      {
        path: 'sub-menu-1',
        component: SubMenuComponent
      },
      {
        path: 'sub-menu-2',
        component: BlankComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },

    ]
  },
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'NewJoinForm',
    component: NewJoiningFormsComponent
  },
  {
    path: 'ReportScreen',
    component: NewJoiningFormReportComponent,
  },
  {
    path: 'FileUpload',
    component: FileuploadComponent
  },
  {
    path: 'Training',
    component: TrainingcertificateComponent
  },
  {
    path: 'PatientCount',
    component: PatientCountDetailsComponent
  },
  {
    path: 'StockUpload',
    component: StockUploadComponent
  },
  {
    path: 'StockList',
    component: StockListComponent
  },
  {
    path: 'OldInvoice',
    component: OldVoiceComponent
  },
  {
    path: 'VendorPayment',
    component: VendorPaymentComponent
  },
  {
    path: 'OverAll-VendorPayment',
    component: OverAllVendorPaymentComponent,
  },
  {
    path: 'AddVendor',
    component: AddVendorComponent
  },
  {
    path: 'dash',
    component: AmddsDashboardComponent
  },
  {
    path: 'SameStockDetails',
    component: SameStockListComponent
  },
  {
    path: 'StockExpiryDetails',
    component: StockExpiryListComponent
  },
  {
    path: 'HospitalVendor',
    component: HospitalVendorDetailsComponent
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'scan-center',
    component: ScanCenterFormComponent
  },
  {
    path: 'scan-center-payment',
    component: ScanCenterPaymentComponent
  },
  {
    path: 'scan-center-doctor-payment',
    component: ScanCenterDoctorPaymentComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'recover-password',
    component: RecoverPasswordComponent,
  },
  {
    path : 'lab-test-form',
    component: LabTestFormComponent,
  },
  {
    path : 'lab-test-view',
    component: LabTestViewComponent,
  },
  {
    path: 'lab-receipt-print',
    component: LabReceiptPrintComponent,
  },
  {
    path :  'scan-center-print',
    component: ScanCenterReceiptPrintComponent,
  },
  {
    path :  'add-product',
    component: AddProductComponent,
  },
  {
    path : 'product-entry',
    component: ProductEntryFormComponent,
  },
  {
    path : 'product-report',
    component: ProductReportTableComponent,
  },
  {
    path : 'stock-adjustment',
    component: StockAdjustmentComponent,
  },
  {
    path: 'marg-dashboard',
    component: MargComponent
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
