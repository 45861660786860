<app-amdds-header></app-amdds-header>
<div class="container-fluid">
  <div class="row py-4"></div>
  <h3 class="ml-3 mt-4">Marg Dashboard</h3>

  <div class="row">
    <div class="col-md-3">
      <a class="nav-link" routerLink="/add-product">
        <div class="small-box bg-info">
          <div class="row mt-1"></div>
          <div class="">
            <h4 class="mb-4 m-3">Add Product</h4>
          </div>
          <div class="row mt-2"></div>
        </div>
      </a>
    </div>

    <div class="col-md-3">
      <a class="nav-link" routerLink="/product-entry">
        <div class="small-box bg-danger">
          <div class="row mt-1"></div>
          <div class="">
            <h4 class="mb-4 m-3">Product Purchase Entry</h4>
          </div>
          <div class="row mt-2"></div>
        </div>
      </a>
    </div>

    <div class="col-md-3">
      <a class="nav-link" routerLink="/product-report">
        <div class="small-box bg-success">
          <div class="row mt-1"></div>
          <div class="">
            <h4 class="mb-4 m-3">Product Report</h4>
          </div>
          <div class="row mt-2"></div>
        </div>
      </a>
    </div>

  </div>
  </div>
<!-- <script async="async" data-cfasync="false" src="//pl21298496.toprevenuegate.com/dd62b079ca34b6003becdf408c267efc/invoke.js"></script>
            <div class="col-lg-8">
              <div class="card">
<div id="container-dd62b079ca34b6003becdf408c267efc"></div>
</div>
          </div> -->
<!-- </div> -->
<app-amdds-footer></app-amdds-footer>
