<app-amdds-header></app-amdds-header>

<div class="container-fluid">
  <div class="row mb-5"></div>
  <div class="row mb-3"></div>
  <h2 class="bold text-center">Anitha Medical Expiry Stock Details Screen</h2>

  <div class="container mt-3">
    <div class="row d-flex justify-content-end">
      <div class="col-lg-3">
        <div class="form-floating">
          <input class="form-control text-bold" type="text" id="Tamount3" [value]="this.totalAmounts"
            aria-label="readonly input example" readonly>
          <label for="Select4">Total Amount</label>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">

      <div class="col-lg-2">
        <div class="card mt-3">
          <tr class="table table-sm table-bordered text-center table-hover mb-0">
            <th class="col-1">Select Option <span></span></th>
          </tr>
          <div class="row ms-5 mt-3">
            <div class="form-check ms-3">
              <input type="checkbox" class="form-check-input" id="expiryStockCheckbox"
                (change)="currentExpiryDetails()">
              <label class="form-check-label" for="expiryStockCheckbox">Already Expired</label>
            </div>
            <div class="form-check ms-3" *ngFor="let days of expiryDays">
              <input class="form-check-input" type="checkbox" id="{{ days }}days" [(ngModel)]="selectedExpiryDays[days]"
                (change)="getCurrentExpiry()">
              <label class="form-check-label" for="{{ days }}days">Next {{ days }} Days</label>
            </div>
            <div class="mt-3"></div>
          </div>
        </div>
      </div>

      <div class="col-lg-9 mt-3">
        <div class="table-responsive-md table-wrapper-scroll-y tableFixHead">
          <table class="table table-sm table-bordered text-center table-hover mb-0">
            <thead class="header sticky-top">
              <tr>
                <th>S.No</th>
                <th>Product Name</th>
                <th>Batch Code</th>
                <th>Total Quantity</th>
                <th>Unit Price</th>
                <th>MRP Rate</th>
                <th>Total Price</th>
                <th>Expiry Date</th>
              </tr>
            </thead>
            <tbody *ngIf="this.listOfStockDetails.length > 0; else noDataFound">
              <tr *ngFor="let stock of listOfStockDetails| filter: searchText; let i = index">
                <td>{{i + 1}}</td>
                <td>{{stock?.productDetails?.name || '-'}}</td>
                <td>{{stock?.batchCode || '-'}}</td>
                <td>{{stock?.productDetails?.productAvailQty || '-'}}</td>
                <td>{{stock?.unitPrice | currency:'INR':""}}</td>
                <td>{{stock?.mrpRate | currency:'INR':""}}</td>
                <td>{{stock?.totalPrice | currency:'INR':""}}</td>
                <td>{{stock?.expiryDate || '-'}}</td>
              </tr>
            </tbody>
            <ng-template #noDataFound>
              <tr>
                <td colspan="11">Data Not Found</td>
              </tr>
            </ng-template>
          </table>
        </div>
      </div>

    </div>
  </div>
</div>