import { StockPurchaseDetails } from "@/model/stockPurchaseDetails";
import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject, takeUntil } from "rxjs";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';


@Component({
  selector: 'old-invoice',
  templateUrl: './old-invoice.component.html',
  styleUrls: ['./old-invoice.component.scss']
})
export class OldVoiceComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  isModalShow = false;
  entrydata: string[] = [];
  UniqueData: string[] = [];
  stock: StockPurchaseDetails[] = [];
  stockDetails: StockPurchaseDetails[] = []
  names: string[] = [];
  uniqueDates: string[] = [];
  existingInvoiceNo: number[] = [];
  stockPurchaseDetails: StockPurchaseDetails[] = [];
  vendorName: string[] = [];
  adminApproval: string[] = [];
  vendorFilter: string | null = null;
  dateFilter: string | null = null;
  statusFilter: string | null = null;
  blukDetails: number[] = [];
  currentDate: any
  update: number = 0;
  filedata: any;
  count: number = 0;
  isDisabled = true;
  disabledStatus: { [stockId: number]: boolean } = {};
  selectedFiles: any;
  vendorAndInvoice: { vendorName: string, invoiceNo: string }[] = [];

  constructor(private appService: AppService, private datePipe: DatePipe, private spinner: NgxSpinnerService,
    private toastr: ToastrService) {
    const currentDate = new Date();
    const formattedDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
    this.currentDate = formattedDate !== null ? formattedDate : '';
  }

  ngOnInit() {
    this.getAllPurchaseStockDetails()
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      this.filedata = fileReader;

      fileReader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });

        const existingData = this.vendorAndInvoice;
        const existingVendorName = this.vendorName;


        jsonData.forEach((entry: any) => {
          entry.invoiceNo = String(entry.invoiceNo);
          if (typeof entry.date === 'string') {
            const dateParts = entry.date.split('/');
            const formattedDate = new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]));
            entry.date = this.datePipe.transform(formattedDate, 'yyyy-MM-dd');
            console.error('entry.date is a string:', entry.date);
          } else if (typeof entry.date === 'number') {
            const dateFromNumber = new Date((entry.date - 1) * 24 * 60 * 60 * 1000 + Date.UTC(1900, 0, 1));
            entry.date = this.datePipe.transform(dateFromNumber, 'yyyy-MM-dd');
            console.error('entry.date is a number:', entry.date);
          } else {
            console.error('entry.date is neither a string nor a number:', entry.date);
          }
        });

        this.entrydata = [];
        this.UniqueData = [];

        jsonData.forEach((entry: any) => {
          const isMatch = existingData.some(item => item.invoiceNo === entry.invoiceNo && item.vendorName === entry.vendorName);

          if (isMatch) {
            this.isModalShow = true;
            this.entrydata.push(entry);
          } else {
            this.isModalShow = true;
            this.UniqueData.push(entry);
          }
        });

        if (this.entrydata.length > 0) {
          this.isModalShow = true;
        }
      };
      fileReader.readAsArrayBuffer(file);
    }
  }

  ExcelFileUpload() {
    this.appService
      .excelFileUpload(this.UniqueData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isModalShow = false;
        this.toastr.success("Your File Upload Successfully Completed")
        this.UniqueData = [];
        this.entrydata = []
        this.selectedFiles = [];
        this.getAllPurchaseStockDetails()
      }, (error: any) => {
        this.spinner.hide();
        this.toastr.error(error.error?.message)
        this.cencel()
      })
  }

  saveListData() {
    this.spinner.show()
    this.ExcelFileUpload()
  }

  cencel() {
    this.UniqueData = [];
    this.entrydata = [];
    this.selectedFiles = []
    this.isModalShow = false;
  }


  GetStock() {
    this.spinner.show()
    this.appService
      .getAgingDetail()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.stockDetails = data.filter(res => res.vendor !== "VEND-0070");
        this.spinner.hide()
        const existingInvoice = new Set(this.stockDetails.map(item => Number(item.invoiceNo)))
        this.existingInvoiceNo = Array.from(existingInvoice).filter(invoiceNo => invoiceNo !== null)
        const uniqueSet = new Set(this.stockDetails.map(item => item.date));
        this.vendorAndInvoice = this.stockDetails.map(item => ({ vendorName: item.vendorName, invoiceNo: item.invoiceNo }))
          .filter(entry => entry.vendorName !== null && entry.vendorName !== "" && entry.invoiceNo !== null);
        this.uniqueDates = Array.from(uniqueSet).filter(date => date !== null && date !== "")
        const vendorSet = new Set(this.stockDetails.map(item => item.vendorName));
        this.vendorName = Array.from(vendorSet).filter(vendor => vendor !== null && vendor !== "").sort((a, b) => a.localeCompare(b));
        const adminApprovalSet = new Set(this.stockDetails.map(item => item.adminStatus));
        this.adminApproval = Array.from(adminApprovalSet).filter(status => status !== null && status !== "");
        const pending = this.stockDetails.filter(res => res.adminStatus == 'Not Agree')
        this.count = pending.length
        if (this.count !== 0) {
          this.isDisabled = false
          const existingInvoiceer = new Set(pending.map(item => item.stockId))
          this.blukDetails = Array.from(existingInvoiceer)
        }
        this.stock = this.stockDetails
        this.applyFilters()
      }, (err: any) => {
        this.spinner.hide()
        console.log("Something Went Wrong")
        this.toastr.error("Something went wrong , Please try again later....")
      })

  }

  getAllPurchaseStockDetails(){
    this.appService.getAllPurchaseStockDetails()
    .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.stockDetails = data.filter(res => res.vendor !== "VEND-0070");
        this.spinner.hide()
        const existingInvoice = new Set(this.stockDetails.map(item => Number(item.invoiceNo)))
        this.existingInvoiceNo = Array.from(existingInvoice).filter(invoiceNo => invoiceNo !== null)
        const uniqueSet = new Set(this.stockDetails.map(item => item.date));
        this.vendorAndInvoice = this.stockDetails.map(item => ({ vendorName: item.vendorName, invoiceNo: item.invoiceNo }))
          .filter(entry => entry.vendorName !== null && entry.vendorName !== "" && entry.invoiceNo !== null);
        this.uniqueDates = Array.from(uniqueSet).filter(date => date !== null && date !== "")
        const vendorSet = new Set(this.stockDetails.map(item => item.vendorName));
        this.vendorName = Array.from(vendorSet).filter(vendor => vendor !== null && vendor !== "").sort((a, b) => a.localeCompare(b));
        const adminApprovalSet = new Set(this.stockDetails.map(item => item.adminStatus));
        this.adminApproval = Array.from(adminApprovalSet).filter(status => status !== null && status !== "");
        const pending = this.stockDetails.filter(res => res.adminStatus == 'Not Agree')
        this.count = pending.length
        if (this.count !== 0) {
          this.isDisabled = false
          const existingInvoiceer = new Set(pending.map(item => item.stockId))
          this.blukDetails = Array.from(existingInvoiceer)
        }
        this.stock = this.stockDetails
        this.applyFilters()
      }, (err: any) => {
        this.spinner.hide()
        console.log("Something Went Wrong")
        this.toastr.error("Something went wrong , Please try again later....")
      })

  }

  updateStock(event: any, filterType: string) {
    const value = event.target.value;
    switch (filterType) {
      case 'vendor':
        this.vendorFilter = value !== 'Vendor' ? value : null;
        break;
      case 'date':
        this.dateFilter = value !== 'Select Date' ? value : null;
        break;
      case 'status':
        this.statusFilter = value !== 'Select Option' ? value : null;
        break;
      default:
        break;
    }
    this.applyFilters();
  }

  applyFilters() {
    const vendorFiltered = this.vendorFilter ? this.stockDetails.filter(item => item.vendorName === this.vendorFilter) : this.stockDetails;
    const dateFiltered = this.dateFilter ? vendorFiltered.filter(item => item.date === this.dateFilter) : vendorFiltered;
    const statusFiltered = this.statusFilter ? dateFiltered.filter(item => item.adminStatus === this.statusFilter) : dateFiltered;
    this.stock = statusFiltered;
  }

  pendingUpdate(stockId: number) {
    const value = stockId
    for (let up of this.stockDetails) {
      if (value == up.stockId) {
        this.update = value
        this.disabledStatus[value] = true;
        this.updateCurrentAdminStatus()
      }
    }
  }

  //New status update function
  updateCurrentAdminStatus() {
    this.spinner.show();
    this.appService
      .updateCurrentAdminStatus(this.update)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
      }, (err: any) => {
        console.log("Something Went Wrong")
        this.spinner.hide();
        this.toastr.error("Something went wrong , Please try again later....")
      });
  }

  updateAdminStatus() {
    this.spinner.show();
    this.appService
      .updateAdminStatus(this.update)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
      }, (err: any) => {
        console.log("Something Went Wrong")
        this.spinner.hide();
        this.toastr.error("Something went wrong , Please try again later....")
      });
  }


  UpdateAlert() {
    Swal.fire({
      title: " Do you want to Approved All Pending Details?",
      icon: "question",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
      backdrop: false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.updateAdminBulkStatus()
      } else if (result.isDenied) {
        window.location.reload()
      }
    });
  }


  updateBlukAdminStatus() {
    this.spinner.show();
    this.appService
      .updateBulkStatus(this.blukDetails)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
        this.toastr.success("Admin Status updated Successfully")
        this.getAllPurchaseStockDetails()
      }, (error: any) => {
        this.spinner.hide()
        this.toastr.error("Something Went Wrong")
      })
  }

  //New bulk admin status update
  updateAdminBulkStatus() {
    this.spinner.show();
    this.appService
      .updateAdminBulkStatus(this.blukDetails)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
        this.toastr.success("Admin Status updated Successfully")
        this.getAllPurchaseStockDetails()
      }, (error: any) => {
        this.spinner.hide()
        this.toastr.error("Something Went Wrong")
      })
  }


}
