<app-amdds-header></app-amdds-header>
<app-vendor-navbar></app-vendor-navbar>

<div class="container-fluid">
    <div class="row py-5"></div>

    <section>
        <div class="row mt-3">
            <div class="row ml-1 mr-1">
                <div class="col-md-2 mt-2">
                    <div class="form-floating">
                        <select class="form-select" id="Select" aria-label="Floating label select"
                            (change)="updateStock($event, 'status')">
                            <option selected>Select Approval</option>
                            <option *ngFor="let status of this.adminStatus" [value]="status">{{ status === 'YES' ?
                                'Approved' :
                                'Not Approved' }}</option>
                        </select>
                        <label for="Select">Admin Approval</label>
                    </div>
                </div>
                <div class="col-md-2 mt-2">
                    <div class="form-floating">
                        <select class="form-select" id="Select1" aria-label="Floating label select"
                            (change)="updateStock($event, 'approval')">
                            <option selected>Select Payment Status</option>
                            <option *ngFor="let approval of this.adminApproval">{{approval}}</option>
                        </select>
                        <label for="Select1">Payment Status</label>
                    </div>
                </div>
                <div class="col-md-2 mt-2">
                    <div class="form-floating">
                        <select class="form-select" id="Select2" aria-label="Floating label select"
                            (change)="updateStock($event, 'vendor')">
                            <option selected>Select vendor Name</option>
                            <option *ngFor="let name of this.vendorName">{{name}}</option>
                        </select>
                        <label for="Select2">Vendor Name</label>
                    </div>
                </div>
                <div class="col-md-2 mt-2">
                    <div class="form-floating">
                        <select class="form-select" id="Select3" aria-label="Floating label select"
                            (change)="updateStock($event, 'bankType')">
                            <option selected>Select Bank Type</option>
                            <option value="Same Bank">Same Bank</option>
                            <option value="Other Bank">Other Bank</option>
                        </select>
                        <label for="Select3">Bank Type</label>
                    </div>
                </div>
                <div class="col-md-2 mt-2">
                    <div class="form-floating">
                        <input class="form-control text-bold" type="text" id="Tamount3" value="Anitha Medical"
                            aria-label="readonly input example" readonly>
                        <label for="Select4">Bank to Process</label>
                    </div>
                </div>
                <div class="col-md-2 mt-2">
                    <div class="form-floating">
                        <select class="form-select" id="Select5" aria-label="Floating label select"
                            (change)="updateStock($event, 'date')">
                            <option selected>Select Paid Date</option>
                            <option *ngFor="let date of this.uniqueDates">{{date}}</option>
                        </select>
                        <label for="Select5">Payment Date</label>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="row ml-1 mr-1 mt-1">

        <div class="col-md-2 mt-3">
            <button type="button" class="btn btn-primary" [disabled]="isDisabled" (click)="dowloadfuntion()"> <span
                    class="ml-2"></span>View Batch File</button>
            <button type="button" class="btn btn-secondary ml-1">{{this.count}}</button>
            <button type="button" class="btn btn-primary  mt-1 mb-1" (click)="generateVistorsXLSX()"><span
                    class="ml-2"></span>Generate Report <span class="ml-3"></span></button><br>
            <button type="button" class="btn btn-primary text-center" (click)="updateStock($event, '')"><span
                    class="ml-2"></span> <span class="ml-4"></span>Search<span class="ml-5"></span></button>
        </div>

        <div class="col-md-2  mt-3">
            <div class="form-floating">
                <input class="form-control text-center text-bold" type="text" id="Tamount"
                    value="{{totalAmountAbove90}}" aria-label="readonly input example" readonly>
                <label for="Tamount" class="text-sm">Total Amount(Above 90 Days)</label>
            </div>
        </div>

        <div class="col-md-2  mt-3">
            <div class="form-floating">
                <input class="form-control text-center text-bold" type="text" id="Tamount1"
                    value="{{totalInvoiceAmount60to90}}" aria-label="readonly input example" readonly>
                <label for="Tamount1" class="text-sm">Total Amount(60 to 90 Days)</label>
            </div>
        </div>

        <div class="col-md-2  mt-3">
            <div class="form-floating">
                <input class="form-control text-center text-bold" type="text" id="Tamount2"
                    value="{{totalInvoiceAmount30to60}}" aria-label="readonly input example" readonly>
                <label for="Tamount2" class="text-sm">Total Amount(30 to 60 Days)</label>
            </div>
        </div>

        <div class="col-md-2  mt-3">
            <div class="form-floating">
                <input class="form-control text-center text-bold" type="text" id="Tamount3"
                    value="{{this. totalInvoiceAmount30 }}" aria-label="readonly input example" readonly>
                <label for="Tamount3" class="text-sm">Total Amount(0 to 30 Days)</label>
            </div>
        </div>

        <div class="col-md-2  mt-3">
            <div class="form-floating">
                <input class="form-control text-center text-bold" type="text" id="Tamount4"
                    value="{{ this.totalInvoiceAmount }} " aria-label="readonly input example" readonly>
                <label for="Tamount4" class="text-sm">Total Amount</label>
            </div>
        </div>

    </div>

    <div class="row ml-2 mr-2">

        <div class="col-md-1 mt-2">
            <div class="row">
                <div class="table-responsive table-bordered table-striped text-center"
                    style="height: 370px;overflow-y:scroll">
                    <table class="table text-center table-head-fixed">
                        <thead class="">
                            <tr>
                                <th class="col-1">Select Option <span><i class='fas fa-angle-down'></i></span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="col-1">
                                    <div class="form-check" *ngFor="let aging of this.daysDifference">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                            [checked]="selectedAgingValues.includes(aging)"
                                            (change)="toggleCheckbox(aging)">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            {{ aging }}
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="col-md-11 mt-2">
            <div class="col-md-12 table-responsive-md table-wrapper-scroll-y tableFixHead">
                <table class="table table-sm table-bordered text-center table-hover mb-0">
                    <thead class="header sticky-top">
                        <tr>
                            <th>SL.NO</th>
                            <th>Invoice Date</th>
                            <th>Invoice No</th>
                            <th>Aging</th>
                            <th>Vendor Name</th>
                            <th>Invoice Amount</th>
                            <th>To Pay Amount</th>
                            <th style="width: 90px;">CN Amount</th>
                            <th>Admin Approval</th>
                            <th>Transaction ID</th>
                            <th>Paid Date</th>
                            <th>PaymentStatus</th>
                            <th>Comments</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="this.stock.length > 0; else noDataFound">
                        <tr class="widthset" *ngFor="let data of this.stock; let i=index">
                            <td>{{ i+1 }}</td>
                            <td>{{ data.date }}</td>
                            <td>{{ data.invoiceNo }}</td>
                            <td>{{ data.aging }}</td>
                            <td>{{ data.vendorName }}</td>
                            <td [ngClass]="{'negative-value': data.invoiceAmount < 0}">{{ data.invoiceAmount |
                                currency:'INR':'' }}</td>
                            <td [ngClass]="{'negative-value': data.grantTotal < 0}">{{ data.grantTotal |
                                currency:'INR':'' }}</td>
                            <td style="width: 90px;">
                                <p class="" *ngIf="data.doctorStatus == 'YES'">{{ data.cnAmount | currency:'INR':''}}
                                </p>
                                <input *ngIf="data.doctorStatus == 'No'" class="form-control text1"
                                    (input)="currentAMountDetails($event,data.stockId)">
                            </td>
                            <td>
                                <i class="fas fa-check" style="color: green;" *ngIf="data.doctorStatus == 'YES'"></i>
                                <button type="button" *ngIf="data.doctorStatus == 'No'"
                                    class="btn btn-primary hidestatus" (click)="updateAdminStatus(data.stockId)"
                                    [disabled]="disabledStatus[data.stockId]"
                                    [ngStyle]="{'color': disabledStatus[data.stockId] ? 'white' : 'inherit', 'background-color': disabledStatus[data.stockId] ? 'black' : 'primary'}">Pending</button>
                            </td>
                            <td>
                                <input type="text"
                                    *ngIf="data.transactionId == '' && data.doctorStatus == 'YES' || data.transactionId == null && data.doctorStatus == 'YES' "
                                    class="form-control text1" (change)="transID( $event,data.stockId)">
                                <input type="text"
                                    *ngIf="data.transactionId == '' && data.doctorStatus == 'No' || data.transactionId == null && data.doctorStatus == 'No' "
                                    class="form-control text1" [disabled]="true">
                                <p class="text-center" *ngIf="data.transactionId !== ''">{{ data.transactionId }}</p>
                            </td>
                            <td>
                                <input type="date"
                                    *ngIf="data.payDate == '' && data.doctorStatus == 'YES' || data.payDate == null && data.doctorStatus == 'YES'"
                                    (change)="payDATE($event,data.stockId)" class="form-control text1">
                                <input type="date"
                                    *ngIf="data.payDate == '' && data.doctorStatus == 'No' || data.payDate == null && data.doctorStatus == 'No'"
                                    class="form-control text1" [disabled]="true">
                                <p class="text-center" *ngIf="data.payDate !== '' ">{{ data.payDate }}</p>
                            </td>
                            <td>{{ data.paymentStatus}}</td>
                            <td>
                                <span
                                    *ngIf="data.status == '' && data.doctorStatus == 'No' || data.status == null && data.doctorStatus == 'No' "
                                    (click)="commandPopUp(data.stockId)"><i class='far fa-comment-alt'
                                        style='font-size:24px'></i></span>
                                <input type="text"
                                    *ngIf="data.status == '' && data.doctorStatus == 'YES' || data.status == null && data.doctorStatus == 'YES' "
                                    class="form-control text1" [disabled]="true">
                                <p class="text-center" *ngIf="data.status !== ''">{{ data.status }}</p>
                            </td>
                        </tr>
                    </tbody>
                    <ng-template #noDataFound>
                        <tr>
                            <td colspan="11">Data Not Found</td>
                        </tr>
                    </ng-template>
                </table>
            </div>
        </div>

    </div>
</div>

<div class="modal" class="modal" id="exampleModalCenter" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true" [style.display]="showCommand ? 'block' : 'none'">
    <form [formGroup]="Form">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add Your Comments</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        (click)="close()"></button>
                </div>
                <div class="modal-body">
                    <div class="form-floating">
                        <input class="form-control text-bold" type="text" id="Tamount3" formControlName='status'
                            aria-label="readonly input example">
                        <label for="Select4">Enter Your Comments</label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                        (click)="command()">Ok</button>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal" class="modal" id="exampleModalCenter" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true" [style.display]="showBatchFile ? 'block' : 'none'">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <div class="row col-md-12">
                    <!-- <h5 class="modal-title">Add Your Comments</h5> -->
                    <div class="col-md-6 col-12 col-sm-10">
                        <div class="form-floating">
                            <select class="form-select" id="Select3" aria-label="Floating label select"
                                (change)="selectBankType($event)">
                                <option selected>Select Bank Type</option>
                                <option value="Same Bank">Same Bank</option>
                                <option value="Other Bank">Other Bank</option>
                            </select>
                            <label for="Select3">Bank Type</label>
                        </div>
                    </div>
                    <div class="col-md-6 col-12 col-sm-2 d-flex justify-content-end align-items-end mb-3">
                        <button type="button" class="btn btn-danger" (click)="showBatchFile=false;" data-dismiss="modal"
                            (click)="cancelGenerateFile()" aria-label="Close" matTooltip="Click to close the table">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="modal-body">
                <div class="col-md-11 mt-2">
                    <div class="col-md-12 table-responsive-md table-wrapper-scroll-y tableFixHead">
                        <table class="table table-bordered text-center table-hover mb-0">
                            <thead class="header sticky-top">
                                <tr>
                                    <th>SL.NO</th>
                                    <th>Select All <br><input type="checkbox" [(ngModel)]="selectAllChecked"
                                            (change)="selectAllPaymentProcess(batchFileDetails, $event)"></th>
                                    <th>Invoice Date</th>
                                    <th>Invoice No</th>
                                    <th>Aging</th>
                                    <th>Vendor Name</th>
                                    <th>Invoice Amount</th>
                                    <th>To Pay Amount</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="batchFileDetails.length > 0; else noDataFound">
                                <tr class="widthset" *ngFor="let data of batchFileDetails; let i=index">
                                    <td>{{ i+1 }}</td>
                                    <td><input type="checkbox" [(ngModel)]="data.selected"
                                            (change)="selectPaymentProcess(data, $event)"></td>
                                    <td>{{ data.date }}</td>
                                    <td>{{ data.invoiceNo }}</td>
                                    <td>{{ data.aging }}</td>
                                    <td>{{ data.vendorName }}</td>
                                    <td [ngClass]="{'negative-value': data.invoiceAmount < 0}">{{ data.invoiceAmount |
                                        currency:'INR':'' }}</td>
                                    <td [ngClass]="{'negative-value': data.grantTotal < 0}">{{ data.grantTotal |
                                        currency:'INR':'' }}</td>
                                </tr>
                            </tbody>
                            <ng-template #noDataFound>
                                <tr>
                                    <td colspan="11">Data Not Found</td>
                                </tr>
                            </ng-template>
                        </table>
                    </div>
                </div>
            </div>

            <div class="modal-footer d-flex justify-content-between align-items-center">
                <div class="form-floating">
                    <input type="text" class="form-control text-bold" placeholder="Select Pay Total Amount"
                        [value]="totalPayAmount | currency:'INR':''" readonly>
                    <label for="Select3">Select Pay Total Amount</label>
                </div>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    (click)="GenerateBatchFile()">GenerateBatchFile</button>
            </div>
        </div>
    </div>
</div>

<ngx-spinner>
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>

<app-amdds-footer></app-amdds-footer>