<div class="receipt">
  <div class="">
    <div class="row text-center">
      <div class="col-12 mt-3">
        <img src="../../../assets/img/vhscancenter-logo.jpeg" alt="Logo"
        style="text-align: center;">
        <!-- <h4 class="text-uppercase text-bold mt-2 headerTitle">Varshini Hospital</h4> -->
        <div class="text-center headerSubTitle">
          #1, First Cross St, Sundar Nagar, Trichy 21
        </div>
        <h6 class="mt-2">****** www.varshinihospital.com ******</h6>
      </div>
    </div>
  </div>
  <hr>
  <div id="location">
    <div class="text-bold text-uppercase text-center location"><u>Scan Center Receipt</u></div>
  </div>

  <div class="row mt-3">
    <table class="table table-borderless">
      <thead>
      <tr>
        <th>No - {{scanDetails.scanId}}</th>
        <th class="text-end">Dated: {{scanDetails.createdAt | date: 'MM-dd-yyyy / hh:mm a' }}</th>
      </tr>
      <tr>
        <td>Patient Name:</td>
        <td>{{scanDetails.patientName}}</td>
      </tr>
      <tr>
        <td>Patient Age:</td>
        <td>{{scanDetails.age}}</td>
      </tr>
      <tr>
        <td>Patient Gender:</td>
        <td>{{scanDetails.gender}}</td>
      </tr>
      <tr>
        <td>Scan Test Details:</td>
        <td>{{scanDetails.scanType}}</td>
      </tr>
      <tr class="amount">
        <td>Amount Received:</td>
        <td>
          <h5 class="price"> ₹ {{(scanDetails.referenceDoctorFees) + (scanDetails.nikithaScanFees) + (scanDetails.scanDoctorFees) }} /-</h5>
          <!-- <h5 class="price"> ₹ 100 /-</h5> -->
        </td>
      </tr>
      </thead>
    </table>
  </div>
  <!-- <div class="">
    <div class="row">
      <div class="col-3"></div>
      <div class="col-4 text-center">
        <img class="" src="{{vehicleQrCodeUrl}}" style="height: 500px; border: 4px solid #c54848">
      </div>
      <div class="col-4"></div>
    </div>
  </div> -->
  <div class="keepIt">
    <div class="row mt-4 mb-5">
      <div class="col-12 text-center keepIt">
        <!-- <h6 class="keepIt">We wish you for your Speedy Recovery</h6> -->
        <h5 class="keepIt font-italic">***** We wish you for your Speedy Recovery *****</h5>
      </div>
    </div>
  </div>
</div>