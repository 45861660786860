import { Component, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { Subject, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { AddStockDetails } from "@/model/addStockDetails";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import * as CryptoJS from 'crypto-js';
import { ScanCenterDetails } from "@/model/scenCenterDetails";
import { Router } from "@angular/router";

@Component({
  selector: "app-scan-center-form",
  templateUrl: "./scan-center-form.component.html",
  styleUrls: ["./scan-center-form.component.scss"]
})
export class ScanCenterFormComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  dailyStockDetails: AddStockDetails[] = [];
  daysDifference: { description: string, totalStock: string }[] = []
  currentDate: String;
  getDate: string = "";
  searchText: any;
  entrydata: string[] = [];
  UniqueData: string[] = [];
  getScanDoctorAmount: number = 0;
  getReferenceAmount: number = 0;
  getNikitaScanFees: number = 0;
  scanDetails : ScanCenterDetails;
  userType : string;

  constructor(private apiService: AppService, private datePipe: DatePipe, private toastr: ToastrService,
    private spinner: NgxSpinnerService,private router: Router) {
    const currentDate = new Date();
    this.currentDate = this.datePipe.transform(currentDate, "yyyy-MM-dd") ?? "";
  }

  scanForm = new UntypedFormGroup({
    patientName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    scanName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    scanDoctorFees: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    referenceDoctorFees: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    nikithaScanFees: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    scanDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    scanType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    totalScanAmount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    age: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    gender: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
  })

  public scanFormError = {
    patientName: "",
    scanDoctorFees: "",
    referenceDoctorFees: "",
    nikithaScanFees: "",
    scanDate: "",
    scanType: "",
    totalScanAmount: "",
    age: "",
    gender: "",
  }


  ngOnInit(): void {
    this.userType = localStorage.getItem("userType");
    this.scanForm.controls['scanDate'].setValue(this.currentDate);
  }

  encryptData(data: any) {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret-key').toString();
    return encryptedData;
  }

  onSubmit() {
    this.formValidation()
  }


  formValidation() {
    this.scanFormError.patientName = "";
    this.scanFormError.scanDoctorFees = "";
    this.scanFormError.referenceDoctorFees = "";
    this.scanFormError.nikithaScanFees = "";
    this.scanFormError.scanDate = "";
    this.scanFormError.scanType = "";
    this.scanFormError.totalScanAmount = "";
    this.scanFormError.age = "";
    this.scanFormError.gender = "";
    let hasError = false;

    if (this.scanForm.get("scanDate")?.invalid) {
      this.scanFormError.scanDate = "*Scan Date is Required";
      hasError = true;
    }

    if (this.scanForm.get('patientName')?.invalid) {
      this.scanFormError.patientName = "*Patient Name is Required";
      hasError = true;
    }

    if (this.scanForm.get('age')?.invalid) {
      this.scanFormError.age = "*Age is Required";
      hasError = true;
    }

    if (this.scanForm.get('gender')?.invalid) {
      this.scanFormError.gender = "*Gender is Required";
      hasError = true;
    }

    if (this.scanForm.get('scanDoctorFees')?.invalid) {
      this.scanFormError.scanDoctorFees = "*Scan Doctor Fees is Required";
      hasError = true;
    }

    if (this.scanForm.get('referenceDoctorFees')?.invalid) {
      this.scanFormError.referenceDoctorFees = "*Reference Doctor Fees is Required";
      hasError = true;
    }

    if (this.scanForm.get('nikithaScanFees')?.invalid) {
      this.scanFormError.nikithaScanFees = "*Nikhitha Scan Fees is Required";
      hasError = true;
    }

    if (this.scanForm.get('scanType')?.invalid) {
      this.scanFormError.scanType = "*Scan Type is Required";
      hasError = true;
    }

    if (this.scanForm.get('totalScanAmount')?.invalid) {
      this.scanFormError.totalScanAmount = "*Total Scan Amount is Required";
      hasError = true;
    }

    if (!hasError) {
      this.saveScanData();
    }

  }


  onScanDoctor(event: any): void {
    this.getScanDoctorAmount = parseFloat(event.target.value) || 0;
    this.updateTotalAmount();
  }

  onReferenceDoctor(event: any): void {
    this.getReferenceAmount = parseFloat(event.target.value) || 0;
    this.updateTotalAmount();
  }

  onNikita(event: any): void {
    this.getNikitaScanFees = parseFloat(event.target.value) || 0;
    this.updateTotalAmount();
  }

  updateTotalAmount(): void {
    const totalAmount = (this.getScanDoctorAmount || 0) + (this.getReferenceAmount || 0) + (this.getNikitaScanFees || 0);
    this.scanForm.controls['totalScanAmount'].setValue(totalAmount);
  }

  saveScanData() {
    this.spinner.show();
    this.apiService.saveScanDetails(this.scanForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
          this.spinner.hide();
          this.scanDetails = data;
          this.successAlert();
      }, (error) => {
      }), (error) => {
        this.spinner.hide();
        this.toastr.error(error.message);
      }
  }

  successAlert() {
    Swal.fire({
      title: "Scan Details Saved Successfully",
      confirmButtonText: "Ok",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        const encryptedData = this.encryptData(this.scanDetails);
        this.router.navigate(['/scan-center-print', { data: encryptedData }]);
      }
    });
  }

}
