import { StockPurchaseDetails } from "@/model/stockPurchaseDetails";
import { Component, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from "file-saver";
import { ToastrService } from "ngx-toastr";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-vendorPayment',
  templateUrl: './vendorPayment.component.html',
  styleUrls: ['./vendorPayment.component.scss']
})

export class VendorPaymentComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  stockDetails: StockPurchaseDetails[] = [];
  stock: StockPurchaseDetails[] = [];
  totalInvoiceAmount: string = '';
  totalInvoiceAmount30: string = '';
  totalInvoiceAmount30to60: string = '';
  totalInvoiceAmount60to90: string = '';
  totalAmountAbove90: string = '';
  daysDifference: number[] = []
  adminApproval: string[] = [];
  bankType: string[] = [];
  names: string[] = [];
  uniqueDates: string[] = [];
  vendorName: string[] = [];
  entrydata: string[] = [];
  UniqueData: string[] = [];
  adminStatus: string[] = [];
  vendorFilter: string | null = null;
  dateFilter: string | null = null;
  statusFilter: string | null = null;
  approvelFilter: string | null = null;
  agingDetails: number | null = null;
  bankTypeFilter: string | null = null;
  selectedAgingValues: number[] = [];
  upgrade: number = 0;
  transId: number;
  stockIds: number;
  isDisabled = true;
  count: number = 0;
  multiClick: StockPurchaseDetails[] = [];
  batchFileDetails: StockPurchaseDetails[] = [];
  finalGenerateBatchFileDetails: StockPurchaseDetails[] = [];
  disabledStatus: { [stockId: number]: boolean } = {};
  isUpgradeButtonDisabled: boolean = false;
  showCommand: boolean = false;
  currentPaidAmount: number = 0;
  showBatchFile: boolean = false;
  totalPayAmount: number = 0;
  selectAllChecked: boolean = false;
  updateJson: { id: number, grantAmount: number, cnAmount: number, status: string };

  inputValues: { transactionId: string, stockId: number, payDate: string }[] = [];
  updatedCommants: string = ""
  constructor(private appservice: AppService, private spinner: NgxSpinnerService, private tostar: ToastrService) {
  }
  ngOnInit() {
    this.GetStock()
  }

  Form = new UntypedFormGroup({
    stockId: new UntypedFormControl("", Validators.required),
    transactionId: new UntypedFormControl("", Validators.required),
    payDate: new UntypedFormControl("", Validators.required),
    status: new UntypedFormControl("", Validators.required),
  })

  FormError = {
    transactionId: '',
    payDate: '',
  }
  FormValidation() {
    this.FormError.transactionId = ''
    this.FormError.payDate = ''

    let hasError = false;
    if (this.Form.get('transactionId')?.invalid) {
      this.tostar.error("Enter TransactionID")
      hasError = true;
    }
    if (this.Form.get('payDate')?.invalid) {
      this.tostar.error("Enter Date")
      hasError = true;
    }
    if (!hasError) {
    }
  }

  GetStock() {
    this.spinner.show()
    this.appservice
      .getAgingDetail()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.stockDetails = data.filter(res => res.date != null && res.adminStatus === "Agree" && res.vendor !== "VEND-0070" && res.deleteYn.toLowerCase() === 'no')
        var localdata: number[] = []
        for (let age of this.stockDetails) {
          localdata.push(age.aging)
        }
        this.spinner.hide()
        const currentAging = new Set(this.stockDetails.map(item => item.aging));
        this.daysDifference = Array.from(currentAging).filter(aging => aging !== null)
        const uniqueSet = new Set(this.stockDetails.map(item => item.payDate));
        this.uniqueDates = Array.from(uniqueSet).filter(date => date !== null && date !== "").sort((a, b) => a.localeCompare(b));
        const vendorSet = new Set(this.stockDetails.map(item => item.vendorName));
        this.vendorName = Array.from(vendorSet).filter(vendor => vendor !== null && vendor !== "").sort((a, b) => a.localeCompare(b));
        const adminstatus = new Set(this.stockDetails.map(item => item.doctorStatus));
        this.adminStatus = Array.from(adminstatus).filter(status => status !== null && status !== "");
        const adminApprovalSet = new Set(this.stockDetails.map(item => item.paymentStatus));
        this.adminApproval = Array.from(adminApprovalSet).filter(approval => approval !== null && approval !== "");
        const uniquebankTypes = new Set(this.stockDetails.map(item => item.vendorDetails?.bankName));
        this.bankType = Array.from(uniquebankTypes).filter(banktypes => banktypes !== null && banktypes !== "")

        this.stock = this.stockDetails;
        this.calculateTotalInvoiceAmount()
        this.applyFilters()
      }, (err: any) => {
        console.log("Something Went Wrong")
        this.spinner.hide();
        this.tostar.error("Something went wrong , Please try again later....")
      })
  }

  calculateTotalInvoiceAmount() {
    const totalInvoiceAmountNumeric = this.stock.reduce((total, item) => total + item.grantTotal, 0);
    this.totalInvoiceAmount = totalInvoiceAmountNumeric.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const totalAmountAbove30Numeric = this.stock.filter(item => item.aging >= 0 && item.aging <= 30).reduce((total, item) => total + item.grantTotal, 0);
    this.totalInvoiceAmount30 = totalAmountAbove30Numeric.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const totalInvoiceAmount30to60Numeric = this.stock.filter(item => item.aging >= 30 && item.aging < 60).reduce((total, item) => total + item.grantTotal, 0);
    this.totalInvoiceAmount30to60 = totalInvoiceAmount30to60Numeric.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const totalInvoiceAmount60to90Numeric = this.stock.filter(item => item.aging >= 60 && item.aging < 90).reduce((total, item) => total + item.grantTotal, 0);
    this.totalInvoiceAmount60to90 = totalInvoiceAmount60to90Numeric.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const totalAmountAbove90Numeric = this.stock.filter(item => item.aging >= 90).reduce((total, item) => total + item.grantTotal, 0);
    this.totalAmountAbove90 = totalAmountAbove90Numeric.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  }

  updateStock(event: any, filterType: string) {
    const value = event.target.value;
    switch (filterType) {
      case 'vendor':
        this.vendorFilter = value !== 'Select vendor Name' ? value : null;
        break;
      case 'date':
        this.dateFilter = value !== 'Select Paid Date' ? value : null;
        break;
      case 'status':
        this.statusFilter = value !== 'Select Approval' ? value : null;
        break;
      case 'approval':
        this.approvelFilter = value !== 'Select Payment Status' ? value : null;
        break;
      case 'age':
        this.agingDetails = value !== 'Select Option' ? value : null;
        break;
      case 'bankType':
        this.bankTypeFilter = value !== 'Select Bank Type' ? value : null;
      default:
        break;
    }

    this.applyFilters();
  }

  applyFilters() {
    const vendorFiltered = this.vendorFilter ? this.stockDetails.filter(item => item.vendorName === this.vendorFilter) : this.stockDetails;
    const dateFiltered = this.dateFilter ? vendorFiltered.filter(item => item.payDate === this.dateFilter) : vendorFiltered;
    const statusFiltered = this.statusFilter ? dateFiltered.filter(item => item.doctorStatus === this.statusFilter) : dateFiltered;
    const ApprovalFiltered = this.approvelFilter ? statusFiltered.filter(item => item.paymentStatus === this.approvelFilter) : statusFiltered;
    const agingFiltered = this.agingDetails ? ApprovalFiltered.filter(item => Number(item.aging) === Number(this.agingDetails)) : ApprovalFiltered;
    const BankTypeFiltered = this.bankTypeFilter ? agingFiltered.filter(item => {
      if (this.bankTypeFilter === 'Same Bank') {
        return item.vendorDetails?.bankName === 'Indian Overseas Bank';
      } else if (this.bankTypeFilter === 'Other Bank') {
        return item.vendorDetails?.bankName !== 'Indian Overseas Bank';
      }
      return true;
    }) : agingFiltered;

    if (this.selectedAgingValues.length > 0) {
      this.stock = BankTypeFiltered.filter(item => this.selectedAgingValues.includes(item.aging))
    } else if (BankTypeFiltered.some(item => item.doctorStatus === 'YES' && item.paymentStatus === 'Not Paid') && this.approvelFilter && this.statusFilter) {
      this.stock = BankTypeFiltered.sort((a, b) => a.vendorName.localeCompare(b.vendorName));
    } else if (this.dateFilter) {
      this.stock = BankTypeFiltered.sort((a, b) => a.vendorName.localeCompare(b.vendorName));
    }
    else {
      this.stock = BankTypeFiltered;
    }
    const uniqueAgingValues = new Set(BankTypeFiltered.map(item => Number(item.aging)));
    this.daysDifference = Array.from(uniqueAgingValues).sort((a, b) => b - a);
    this.totalclick()
    this.calculateTotalInvoiceAmount()
  }


  toggleCheckbox(aging: number) {
    if (this.selectedAgingValues.includes(aging)) {
      this.selectedAgingValues = this.selectedAgingValues.filter(value => value !== aging);
    } else {
      this.selectedAgingValues.push(aging);
    }
  }

  YESdoctor(stockId: number) {
    const value = stockId;
    if (!this.disabledStatus[value]) {
      for (let up of this.stockDetails) {
        if (value == up.stockId) {
          this.upgrade = value;
          this.disabledStatus[value] = true;
          var currentPaidAmount: number = 0;
          if (up.invoiceAmount >= 0) {
            currentPaidAmount = (up.invoiceAmount - this.currentPaidAmount);
          } else {
            currentPaidAmount = ((-this.currentPaidAmount) - (+up.invoiceAmount));
          }
          this.updateJson = { id: value, grantAmount: currentPaidAmount, cnAmount: this.currentPaidAmount, status: this.updatedCommants }
          if (this.currentPaidAmount == 0) {
            this.updateAdminStatus()
            this.currentPaidAmount = 0;
            this.updatedCommants = "";
            this.Form.get('status').value == "";
          } else if (this.currentPaidAmount != 0) {
            this.updateAmoutAdminStatus()
            this.currentPaidAmount = 0;
            this.updatedCommants = "";
            this.Form.get('status').value == "";
          }
        }
      }
    }
  }

  currentAMountDetails(event: any, stockId: number) {
    const value = event.target.value;
    for (let up of this.stockDetails) {
      if (stockId == up.stockId) {
        this.currentPaidAmount = value;
      }
    }
  }

  updateAdminStatus() {
    this.appservice
      .updateDocterStatus(this.upgrade)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
        },
        (err: any) => {
          console.log("Something Went Wrong");
          this.tostar.error("Something went wrong , Please try again later....")
          this.disabledStatus[this.upgrade] = false;
        }
      );
  }

  updateAmoutAdminStatus() {
    this.appservice
      .updateAmoutDocterStatus(this.updateJson)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
        },
        (err: any) => {
          console.log("Something Went Wrong");
          this.tostar.error("Something went wrong , Please try again later....")
          this.disabledStatus[this.upgrade] = false;
        }
      );
  }


  getTranscationId(event: any) {
    const value = event.target.value
  }


  updateTransactionId() {
    this.spinner.show()
    this.appservice
      .updateTransactionID(this.Form.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
      }, (error) => {
        this.spinner.hide()
        console.log("ERROR", error);
        this.tostar.error("Something went wrong , Please try again later....")
      });
  }

  totalclick() {
    var localdata: StockPurchaseDetails[] = []
    for (let id of this.stock) {
      if (id.doctorStatus == 'YES' && id.paymentStatus == "Not Paid") {
        this.isDisabled = false;
        localdata.push(id)
      } else {
        this.isDisabled = true;
      }
    }
    this.count = localdata.filter(item => item.grantTotal > 0).length

    this.multiClick = localdata.sort((a, b) => a.vendorName.localeCompare(b.vendorName)).filter(item => item.grantTotal > 0)
  }

  transID(event: any, stockid: number) {
    this.Form.controls['status'].setValue('')
    const value = event.target.value;
    const stock = stockid
    this.transId = value;

    this.inputValues.push({ transactionId: value, stockId: stock, payDate: '' });
  }

  commandPopUp(stockid: number) {
    this.updatedCommants = "";
    this.Form.controls['status'].setValue("")
    const stock = stockid;
    for (let id of this.stock) {
      if (stock == id.stockId) {
        this.showCommand = true;
      } else {

      }
    }
  }

  command() {
    this.updatedCommants = this.Form.get('status').value;
    this.showCommand = false;
  }

  close() {
    this.showCommand = false;
  }

  payDATE(event: any, stockid: number) {
    const selectedDate = event.target.value;
    const stock = stockid
    var localdate: any;
    var command: any;
    for (let data of this.inputValues)
      if (data.stockId == stockid) {
        localdate = data
      }
    this.Form.controls['stockId'].setValue(localdate.stockId);
    this.Form.controls['transactionId'].setValue(localdate.transactionId);
    this.Form.controls['payDate'].setValue(selectedDate);
    this.updateTransactionId()
  }

  generateVistorsXLSX() {
    const desiredColumns = ['date', 'invoiceNo', 'aging', 'vendorDetails.vendorName', 'vendorDetails.bankName', 'grantTotal', 'transactionId', 'payDate', 'paymentStatus'];

    const header = desiredColumns.map(column => {
      const parts = column.split('.');
      return parts[parts.length - 1];
    });

    const headerStyle = {
      fill: {
        fgColor: { rgb: 'black' },
      },
      font: {
        color: { rgb: 'FFFFFF' },
        bold: true,
      },
    };

    const filteredData = this.stock.map(item =>
      desiredColumns.reduce((obj, key) => {
        const keys = key.split('.');
        let value: any = item;
        keys.forEach(k => {
          if (value && typeof value === 'object' && k in value) {
            value = value[k];
          } else {
            value = undefined;
          }
        });
        if (key === 'invoiceAmount' && typeof value === 'number') {
          value = value.toFixed(2);
        }

        obj[key] = value;
        return obj;
      }, {})
    );

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredData);

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    saveAs(data, 'AMVendorPayment' + ' Report.xlsx');
  }

  dowloadfuntion() {
    this.showBatchFile = true;
    // const isSameBank = this.multiClick.some(note => note.vendorDetails.bankName === "Indian Overseas Bank");

    // if (isSameBank) {
    //   this.downloadNotepadFile('AMIOB.txt', this.formatSameBankStockDetailsAsString());
    // } else {
    //   this.downloadNotepadFile('AMOTHER.txt', this.formatStockDetailsAsString());
    // }
  }

  selectBankType(event: any) {
    this.batchFileDetails = [];
    this.totalPayAmount = 0;
    this.finalGenerateBatchFileDetails = [];
    const value = event.target.value;
    const BankTypeFiltered = this.multiClick.filter(item => {
      if (value === 'Same Bank') {
        return item.vendorDetails?.bankName === 'Indian Overseas Bank';
      } else if (value === 'Other Bank') {
        return item.vendorDetails?.bankName !== 'Indian Overseas Bank';
      }
    });
    this.batchFileDetails = BankTypeFiltered.sort((a, b) => a.vendorName.localeCompare(b.vendorName))
    console.log(BankTypeFiltered)
  }

  selectPaymentProcess(data: any, event: any) {
    if (event.target.checked) {
      this.finalGenerateBatchFileDetails.push(data);
      this.totalPayAmount += data.grantTotal;
    } else {
      this.finalGenerateBatchFileDetails = this.finalGenerateBatchFileDetails.filter(item => item !== data);
      this.totalPayAmount -= data.grantTotal;
      this.selectAllChecked = false;
    }
    console.log(this.finalGenerateBatchFileDetails);
  }
  
  selectAllPaymentProcess(data: any[], event: any) {
    if (event.target.checked) {
      this.finalGenerateBatchFileDetails = [...data];
      this.totalPayAmount = data.reduce((total, item) => total + item.grantTotal, 0);
      this.batchFileDetails.forEach(item => item.selected = true);
    } else {
      this.finalGenerateBatchFileDetails = [];
      this.totalPayAmount = 0;
      this.batchFileDetails.forEach(item => item.selected = false);
    }
    this.selectAllChecked = event.target.checked;
  }

  GenerateBatchFile() {
    const isSameBank = this.finalGenerateBatchFileDetails.some(note => note.vendorDetails.bankName === "Indian Overseas Bank");

    if (isSameBank) {
      this.downloadNotepadFile('AMIOB.txt', this.formatSameBankStockDetailsAsString());
    } else {
      this.downloadNotepadFile('AMOTHER.txt', this.formatStockDetailsAsString());
    }
  }

  private downloadNotepadFile(fileName: string, content: string) {
    const blob = new Blob([content], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
    this.removedCount();
  }

  private formatStockDetailsAsString(): string {
    const header = 'IFSC Code,Account type,Account Number,Name of the Beneficiary,Address of the Beneficiary,Sender information,Amount';

    const details = this.finalGenerateBatchFileDetails.map(detail => {
      return `${detail.vendorDetails.bankIfscCode},${11},${detail.vendorDetails.bankAccountNo},${detail.vendorDetails.vendorName},${detail.vendorDetails.city},${detail.invoiceNo} Invoice Payment,${detail.grantTotal}.00`;
    }).join('\n');
    this.tostar.success("Batch file Downloaded successfully");
    this.showBatchFile = false;
    window.location.reload();
    // this.finalGenerateBatchFileDetails = [];
    return `${header}\n${details}`;
  }

  private formatSameBankStockDetailsAsString(): string {
    const header = 'Account Number,Amount,Narration';

    const details = this.finalGenerateBatchFileDetails.map(detail => {
      return `${detail.vendorDetails.bankAccountNo},${detail.grantTotal}.00,${detail.invoiceNo} Invoice Payment`;
    }).join('\n');
    this.tostar.success("Batch file Downloaded successfully");
    this.showBatchFile = false;
    window.location.reload();
    // this.finalGenerateBatchFileDetails = [];
    return `${header}\n${details}`;
  }

  removedCount = () => {
    setTimeout(() => {
      this.count = 0;
      this.multiClick = [];
    }, 2000);
  };

  cancelGenerateFile(){
    this.showBatchFile = false;
  }
}