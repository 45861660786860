<app-amdds-header></app-amdds-header>
<app-vendor-navbar></app-vendor-navbar>
<div class="container-fluid">
    <div class="row py-5"></div>
    <form (ngSubmit)="onSubmit()" [formGroup]="newvendor">
        <div class="row mt-2 justify-content-around">
            <div class="border col-lg-5 col-12 mt-4 mb-5 p-2">
                <h5 class="text-muted text-bold p-2">Vendor Information</h5>
                <hr>
                <div class="p-3">

                    <div class="row mt-2">
                        <div class="col-lg-6 col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="Vname" formControlName="vendorName"
                                    placeholder="Vendor Name" (keydown.enter)="focusNext($event, 'Num')"
                                    (blur)="checkVendorName($event)">
                                <label class="text-muted" for="Vname">Vendor Name<span
                                        style="color:red;">*</span></label>
                                <span *ngIf="NewVendorErrors.vendorName" class="text-danger">
                                    {{ NewVendorErrors.vendorName }}
                                </span>

                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" formControlName="mobileNo" id="Num"
                                    placeholder="Mobile Number" (keypress)="numberOnly($event)" maxlength="10"
                                    (keydown.enter)="focusNext($event,'Cty')">
                                <label class="text-muted" for="Num">Mobile Number<span
                                        style="color:red;">*</span></label>
                                <span *ngIf="NewVendorErrors.mobileNo" class="text-danger">{{ NewVendorErrors.mobileNo
                                    }}</span>

                            </div>
                        </div>
                    </div>

                    <div class="row mt-2">

                        <div class="col-lg-6 col-12">

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="Cty" formControlName="city"
                                    placeholder="City" (keydown.enter)="focusNext($event,'Gu')">
                                <label class="text-muted" for="Cty">City<span style="color:red;">*</span></label>
                                <span *ngIf="NewVendorErrors.city" class="text-danger">{{ NewVendorErrors.city }}</span>

                            </div>

                        </div>

                        <div class="col-lg-6 col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" formControlName="gstNo" id="Gu"
                                    placeholder="GST/UIN" (keydown.enter)="focusNext($event,'Select')">
                                <label class="text-muted" for="Gu">GST/UIN<span style="color:red;">*</span></label>
                                <span *ngIf="NewVendorErrors.gstNo" class="text-danger">{{ NewVendorErrors.gstNo
                                    }}</span>

                            </div>
                        </div>

                    </div>
                    <div class="row mt-2">
                    </div>
                </div>
            </div>

            <div class="border col-lg-5 col-12 mt-4 mb-5 p-2">
                <h5 class="text-muted text-bold p-2">Bank Details</h5>
                <hr>
                <div class="p-3">

                    <div class="row mt-2">
                        <div class="col-lg-6 col-12">
                            <div class="form-floating mb-3">
                                <select class="form-select text-muted" formControlName="bankName" id="Select"
                                    aria-label="Floating label select" (keydown.enter)="focusNext($event,'Anum')">
                                    <option selected>Select Bank Name</option>
                                    <option>Allahabad Bank</option>
                                    <option>Andhra Bank</option>
                                    <option>Axis Bank</option>
                                    <option>Bank of Bahrain and Kuwait</option>
                                    <option>Bank of Baroda - Corporate Banking</option>
                                    <option>Bank of Baroda - Retail Banking</option>
                                    <option>Bank of India</option>
                                    <option>Bank of Maharashtra</option>
                                    <option>Canara Bank</option>
                                    <option>Central Bank of India</option>
                                    <option>City Union Bank</option>
                                    <option>Corporation Bank</option>
                                    <option>Equitas Bank</option>
                                    <option>DBS Bank</option>
                                    <option>Deutsche Bank</option>
                                    <option>Development Credit Bank</option>
                                    <option>Dhanlaxmi Bank</option>
                                    <option>Federal Bank</option>
                                    <option>HDFC Bank</option>
                                    <option>ICICI Bank</option>
                                    <option>IDBI Bank</option>
                                    <option>Indian Bank</option>
                                    <option>Indian Overseas Bank</option>
                                    <option>IndusInd Bank</option>
                                    <option>ING Vysya Bank</option>
                                    <option>Jammu and Kashmir Bank</option>
                                    <option>Karnataka Bank Ltd</option>
                                    <option>Karur Vysya Bank</option>
                                    <option>Kotak Bank</option>
                                    <option>Laxmi Vilas Bank</option>
                                    <option>Oriental Bank of Commerce</option>
                                    <option>Punjab National Bank - Corporate Banking</option>
                                    <option>Punjab National Bank - Retail Banking</option>
                                    <option>Punjab & Sind Bank</option>
                                    <option>Shamrao Vitthal Co-operative Bank</option>
                                    <option>South Indian Bank</option>
                                    <option>State Bank of Bikaner & Jaipur</option>
                                    <option>State Bank of Hyderabad</option>
                                    <option>State Bank of India</option>
                                    <option>State Bank of Mysore</option>
                                    <option>State Bank of Patiala</option>
                                    <option>State Bank of Travancore</option>
                                    <option>Syndicate Bank</option>
                                    <option>Tamilnad Mercantile Bank</option>
                                    <option>UCO Bank</option>
                                    <option>Union Bank of India</option>
                                    <option>United Bank of India</option>
                                    <option>Vijaya Bank</option>
                                    <option>Yes Bank</option>
                                </select>
                                <span *ngIf="NewVendorErrors.bankName" class="text-danger">{{ NewVendorErrors.bankName
                                    }}</span>
                                <label>Select Bank Name<span style="color:red;">*</span></label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" formControlName="bankAccountNo" id="Anum"
                                    placeholder="Account Number" (keypress)="numberOnly($event)"
                                    (keydown.enter)="focusNext($event,'Aname')">
                                <label class="text-muted" for="Anum">Account Number<span
                                        style="color:red;">*</span></label>
                                <span *ngIf="NewVendorErrors.bankAccountNo" class="text-danger">{{
                                    NewVendorErrors.bankAccountNo }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-lg-6 col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" formControlName="accHolderName" id="Aname"
                                    placeholder="Account Holder Name" (keydown.enter)="focusNext($event,'Brname')">
                                <label class="text-muted" for="Aname">Account Holder Name<span
                                        style="color:red;">*</span></label>
                                <span *ngIf="NewVendorErrors.accHolderName" class="text-danger">{{
                                    NewVendorErrors.accHolderName }}</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" formControlName="branch" id="Brname"
                                    placeholder="Branch Name" (keydown.enter)="focusNext($event,'Code')">
                                <label class="text-muted" for="Brname">Branch Name<span
                                        style="color:red;">*</span></label>
                                <span *ngIf="NewVendorErrors.branch" class="text-danger">{{ NewVendorErrors.branch
                                    }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-lg-6 col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" formControlName="bankIfscCode" id="Code"
                                    placeholder="IFSC Code" (keydown.enter)="onSubmit()">
                                <label class="text-muted" for="Code">IFSC Code<span style="color:red;">*</span></label>
                                <span *ngIf="NewVendorErrors.bankIfscCode" class="text-danger">{{
                                    NewVendorErrors.bankIfscCode }}</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-lg-12 col-12">
                <button class="btn btn-primary" type="submit">{{this.isUpdate == true ? "Update" : "Save"}}</button>
            </div>
        </div>
    </form>
</div>

<ngx-spinner>
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>

<app-amdds-footer></app-amdds-footer>