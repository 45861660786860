import { Component, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { Subject, take, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { AddStockDetails } from "@/model/addStockDetails";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { ProductInventoryDetails } from "@/model/medical-entry/product-main-inventory/product-inventory";

@Component({
  selector: "app-stock-expiry-list",
  templateUrl: "./stock-expiry-list.component.html",
  styleUrls: ["./stock-expiry-list.component.scss"]
})
export class StockExpiryListComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  dailyStockDetails: AddStockDetails[] = [];
  filterStockDetails: AddStockDetails[] = [];
  getStockDetails: AddStockDetails[] = [];
  currentStockDetails: ProductInventoryDetails[] = [];
  listOfStockDetails: ProductInventoryDetails[] = [];
  currentDate: any;
  getDate: string = "";
  searchText: any;
  isViewShow = false;
  formattedDate: any;
  totalAmounts: string = ""
  expiryDays = [15, 30, 45, 60, 75, 90];
  selectedExpiryDays: { [key: number]: boolean } = {};

  constructor(private appService: AppService, private datePipe: DatePipe, private toastr: ToastrService,
    private spinner: NgxSpinnerService) {
    const currentDate = new Date();
    this.currentDate = this.datePipe.transform(currentDate, "yyyy-MM-dd") ?? "";
  }


  ngOnInit(): void {
    // this.getDailyStock(this.currentDate);
    // const yourDate = this.currentDate;
    // this.formattedDate = new Date()
    this.getAllCurrentStockDetails();
  }



  getDailyStock(date: string) {
    this.spinner.show();
    this.appService.getDailyStockDetails(date)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.getStockDetails = data.sort((a, b) => {
          const expiryDateA = new Date(a.expiryDate);
          const expiryDateB = new Date(b.expiryDate);
          const monthA = expiryDateA.getMonth();
          const yearA = expiryDateA.getFullYear();
          const monthB = expiryDateB.getMonth();
          const yearB = expiryDateB.getFullYear();
          if (yearA !== yearB) {
            return yearA - yearB;
          } else {
            return monthA - monthB;
          }
        });
        var local: AddStockDetails[] = []
        const currentDate = new Date();
        const thirtyDaysLater = new Date();
        thirtyDaysLater.setDate(currentDate.getDate() + 30);
        let totalAmounts: number = 0;
        for (let stock of this.getStockDetails) {
          const expiryDateObj = new Date(stock.expiryDate);
          if (expiryDateObj >= currentDate && expiryDateObj <= thirtyDaysLater) {
            local.push(stock);
            totalAmounts += Number(stock.totalAmount) || 0;
            this.totalAmounts = totalAmounts.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          }
        }
        this.dailyStockDetails = local;
        this.filterStockDetails = this.dailyStockDetails;
        this.spinner.hide();
      }, (err: any) => {
        this.spinner.hide();
        console.log("something went wrong :", err);
        this.toastr.error("Something went wrong , Please try again later....")
      });
  }

  // Get current stock details
  getAllCurrentStockDetails() {
    this.appService.getAllCurrentStockDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log(data);

        // Parse MM/YY expiry dates and sort
        this.currentStockDetails = data.sort((a, b) => {
          const [monthA, yearA] = a.expiryDate.toString().split('/').map(Number);
          const [monthB, yearB] = b.expiryDate.toString().split('/').map(Number);

          const expiryDateA = new Date(`20${yearA}-${monthA}-01`);
          const expiryDateB = new Date(`20${yearB}-${monthB}-01`);

          return expiryDateA.getTime() - expiryDateB.getTime();
        });

        let local: ProductInventoryDetails[] = [];
        const currentDate = new Date();
        const thirtyDaysLater = new Date();
        thirtyDaysLater.setDate(currentDate.getDate() + 30);

        let totalAmounts: number = 0;
        for (let stock of this.currentStockDetails) {
          const [month, year] = stock.expiryDate.toString().split('/').map(Number);
          const expiryDateObj = new Date(`20${year}-${month}-01`); // Convert MM/YY to a Date

          if (expiryDateObj >= currentDate && expiryDateObj <= thirtyDaysLater) {
            local.push(stock);
            totalAmounts += Number(stock.totalPrice) || 0;
          }
        }

        this.listOfStockDetails = local;
        this.totalAmounts = totalAmounts.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

        console.log(this.listOfStockDetails);
        this.spinner.hide();
      }, (err: any) => {
        this.spinner.hide();
        console.log("Something went wrong:", err);
        this.toastr.error("Something went wrong, please try again later.");
      });
  }


  getExpiry() {
    const currentDate = new Date();
    const local: AddStockDetails[] = [];
    let filterApplied = false;
    const isAnyExpirySelected = Object.values(this.selectedExpiryDays).some(value => value);
    let totalAmounts: number = 0;
    for (let stock of this.getStockDetails) {
      const expiryDateObj = new Date(stock.expiryDate);
      if (isAnyExpirySelected) {
        for (let days in this.selectedExpiryDays) {
          if (this.selectedExpiryDays[days]) {
            const futureDate = new Date(currentDate);
            futureDate.setDate(currentDate.getDate() + parseInt(days));
            if (expiryDateObj <= futureDate && expiryDateObj >= currentDate) {
              local.push(stock);
              totalAmounts += Number(stock.totalAmount) || 0;
              this.totalAmounts = totalAmounts.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
              filterApplied = true;
              break;
            }
          }
        }
      } else {
        if (expiryDateObj > currentDate) {
          this.filterStockDetails = this.dailyStockDetails;
          for (let expiry of this.filterStockDetails) {
            totalAmounts += Number(expiry.totalAmount) || 0;
            this.totalAmounts = totalAmounts.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          }
          return
        }
      }
    }
    this.filterStockDetails = local;
  }

  expiryDetails() {
    const currentDate = new Date();
    let totalAmounts: number = 0;
    this.filterStockDetails = this.getStockDetails.filter(stock => {
      const expiryDateObj = new Date(stock.expiryDate);
      return expiryDateObj <= currentDate;
    }).sort((a, b) => {
      const expiryDateA = new Date(a.expiryDate);
      const expiryDateB = new Date(b.expiryDate);
      const monthA = expiryDateA.getMonth();
      const yearA = expiryDateA.getFullYear();
      const monthB = expiryDateB.getMonth();
      const yearB = expiryDateB.getFullYear();
      if (yearA !== yearB) {
        return yearA - yearB;
      } else {
        return monthA - monthB;
      }
    });
    for (let expiry of this.filterStockDetails) {
      totalAmounts += Number(expiry.totalAmount) || 0;
      this.totalAmounts = totalAmounts.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
  }


  getCurrentExpiry() {
    const currentDate = new Date();
    const local: ProductInventoryDetails[] = [];
    let filterApplied = false;
    const isAnyExpirySelected = Object.values(this.selectedExpiryDays).some(value => value);
    let totalAmounts: number = 0;

    for (let stock of this.currentStockDetails) {
      // Convert expiryDate from MM/YY to Date (YYYY-MM-01)
      const [month, year] = stock.expiryDate.toString().split('/').map(Number);
      const expiryDateObj = new Date(`20${year}-${month}-01`);

      if (isAnyExpirySelected) {
        for (let days in this.selectedExpiryDays) {
          if (this.selectedExpiryDays[days]) {
            const futureDate = new Date(currentDate);
            futureDate.setDate(currentDate.getDate() + parseInt(days));

            if (expiryDateObj <= futureDate && expiryDateObj >= currentDate) {
              local.push(stock);
              totalAmounts += Number(stock.totalPrice) || 0;
              filterApplied = true;
              break;
            }
          }
        }
      } else {
        if (expiryDateObj > currentDate) {
          this.listOfStockDetails = local;
          totalAmounts = this.listOfStockDetails.reduce((sum, expiry) => sum + (Number(expiry.totalPrice) || 0), 0);
          this.totalAmounts = totalAmounts.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          return;
        }
      }
    }

    this.listOfStockDetails = local;
    this.totalAmounts = totalAmounts.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  currentExpiryDetails() {
    const currentDate = new Date();
    let totalAmounts: number = 0;

    this.listOfStockDetails = this.currentStockDetails
      .map(stock => {
        const [month, year] = stock.expiryDate.toString().split('/').map(Number);
        return { ...stock, expiryDateObj: new Date(`20${year}-${month}-01`) };
      })
      .filter(stock => stock.expiryDateObj <= currentDate)
      .sort((a, b) => a.expiryDateObj.getTime() - b.expiryDateObj.getTime());

    totalAmounts = this.listOfStockDetails.reduce((sum, expiry) => sum + (Number(expiry.totalPrice) || 0), 0);
    this.totalAmounts = totalAmounts.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }


}
