<app-amdds-header></app-amdds-header>
<nav class="navbar navbar-expand-lg navbar-light mt-5" style="background-color:darkgray">
  <div class="container-fluid">
    <a class="navbar-brand">Lab Test Details</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mx-4 topnav" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto ">
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/lab-test-form"><b>Lab Test Entry</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/lab-test-view"><b>Lab Test Details View</b></a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="container-fluid">
  <div class="row py-5"></div>
  <div class="row mb-3"></div>
  <h2 class="bold text-center">Varshini Hospital Lab Test View Screen</h2>

  <div class="mt-3">
    <div class="row">
      <div class="col-md-4 col-lg-2 col-12">
        <div class="form-floating">
          <input type="date" id="fromDate" class="form-control" (change)="onDate()">
          <label for="fromDate">From Date:</label>
        </div>
      </div>
      <div class="col-md-4 col-lg-2 col-12">
        <div class="form-floating">
          <input type="date" id="toDate" class="form-control" (change)="onDate()">
          <label for="toDate">To Date:</label>
        </div>
      </div>

      <div class="col-md-4 col-lg-2 col-12 mb-2">
        <div class="form-floating">
        <input type="tel" class="form-control" placeholder="Test Amount" [value]="this.totalTestAmount.toFixed(2) | currency:'INR':''" readonly>
        <label fot="label">Total Test Amount</label>
      </div>
      </div>

      <div class="col-md-4 col-lg-2 col-12 mb-2">
        <div class="form-floating">
        <input type="tel" class="form-control" placeholder="Hospital Share Total Amount" [value]="this.hospitalShareTotalAmount.toFixed(2)| currency:'INR':''" readonly>
        <label fot="label">Hospital Share Total Amount</label>
      </div>
      </div>
      <div class="col-md-4 col-lg-2 col-12 mb-2">
        <div class="form-floating">
        <input type="tel" class="form-control" placeholder="Lab Share Total Amount" [value]="this.labShareTotalAmount.toFixed(2) | currency:'INR':''" readonly>
        <label fot="label">Lab Share Total Amount</label>
      </div>
      </div>

      <div class="col-md-4 col-lg-2 col-12 mb-2">
        <div class="form-floating">
        <input type="search" class="form-control" placeholder="Search" [(ngModel)]="searchText">
        <label fot="label">Search</label>
      </div>
      </div>
    </div>
    </div>
  <div class="row">
      <div class="mt-1">
        <div class="table-responsive">
          <table class="table">
            <thead class="rounded">
              <tr class="sticky-top">
                <th class="col-1">No</th>
                <th class="col-2">Patient Name</th>
                <th class="col-1">Patient Age</th>
                <th class="col-2">Lab Test Type</th>
                <th class="col-1">Total Test Amount</th>
                <th class="col-1">Hospital Share Amount</th>
                <th class="col-1">Lab Share Amount</th>
                <th class="col-1">References Doctor Name</th>
                <th class="col-1">Test Date / Time</th>
                <th class="col-2">Payment Status</th>
                <th class="col-1">Print</th>
              </tr>
            </thead>
            <tbody *ngIf="this.labTestDetails.length > 0; else noDataFound">
              <tr *ngFor="let fees of labTestDetails| filter: searchText; let i = index">
                <td>{{i + 1}}</td>
                <td>{{fees.patientName}}</td>
                <td>{{fees.age}}</td>
                <td>{{fees.labTestType}}</td>
                <td>{{fees.amount | currency:'INR':''}}</td>
                <td>{{fees.amount / 2 | currency:'INR':''}}</td>
                <td>{{fees.amount / 2 | currency:'INR':''}}</td>
                <td>{{fees.referenceDoctor}}</td>
                <td>{{ fees.createdAt | date: 'MM-dd-yyyy / hh:mm a' }}</td>
               <td><i class="fas fa-check" style="color: green;" *ngIf="fees.paymentStatus == 'Paid'"></i>
                  <button type="button" *ngIf="fees.paymentStatus != 'Paid' " (click)="UpdatePaymentStatus(fees.labTestId)"
                    class="btn btn-danger" [disabled]="disabledStatus[fees.labTestId]"
                    [ngStyle]="{'color': disabledStatus[fees.labTestId] ? 'white' : 'inherit', 'background-color': disabledStatus[fees.labTestId] ? 'black' : 'primary'}">Not Paid</button>
                </td>
                <td><i class="fa fa-print" (click)="print(fees)"></i></td>
              </tr>
            </tbody>
            <ng-template #noDataFound>
              <tr>
                <td colspan="9" class="text-center">Data Not Found</td>
              </tr>
            </ng-template>
          </table>
        </div>
      </div>
    </div>
  </div>
<ngx-spinner>
  <div class="loader">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>

<app-amdds-footer></app-amdds-footer>