<app-amdds-header></app-amdds-header>

<!--<nav class="navbar navbar-expand-lg navbar-light mt-5" style="background-color:darkgray">
  <div class="container-fluid">
    <a class="navbar-brand">Anitha Medical</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mx-4 topnav" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto ">
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/add-product"><b>Add Product</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/product-entry"><b>Product Purchase Entry</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/product-report"><b>Product Report</b></a>
        </li>
      </ul>
    </div>
  </div>
</nav>-->


<div class="container-fluid py-3">
  <div class="py-5"></div>
  <form [formGroup]="productEntryFrom" (submit)="onSubmit()">
    <div class="row g-2">

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            list="vendorList"
            formControlName="vendorName"
            (input)="selectVendorName($event)"
            placeholder="Select a Vendor Name"
          />
          <label class="form-label">Select a Vendor Name <span class="text-danger">*</span></label>
          <datalist id="vendorList">
            <option *ngFor="let name of getVendorDetails" [value]="name.vendorName"></option>
          </datalist>
        </div>
        <span class="text-danger" *ngIf="productEntryFromError.vendorName">
    {{ productEntryFromError.vendorName }}
  </span>
      </div>

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Enter a Invoice Number" formControlName="invoiceNo"
                 (input)="checkInvoiceBillNo()">
          <label for="productName">Invoice Number <span class="text-danger">*</span></label>
        </div>
        <span class="text-danger"
              *ngIf="this.productEntryFromError.invoiceNo">{{productEntryFromError.invoiceNo}}</span>
      </div>

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Enter a Invoice Amount" formControlName="invoiceAmount"
                 oninput="this.value = this.value.replace(/\D/g, '')">
          <label for="productName">Invoice Amount <span class="text-danger">*</span></label>
        </div>
        <span class="text-danger"
              *ngIf="this.productEntryFromError.invoiceAmount">{{productEntryFromError.invoiceAmount}}</span>
      </div>

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input type="date" class="form-control" placeholder="Select a Invoice Date" formControlName="date"
                 (change)="dateFormatted($event)">
          <label for="productName">Invoice Date <span class="text-danger">*</span></label>
        </div>
        <span class="text-danger" *ngIf="this.productEntryFromError.date">{{productEntryFromError.date}}</span>
      </div>

    </div>

    <div class="card g-2 mt-2">
      <div class="row">
        <div class="card-body">

          <div class="row">
            <div class="col-12 text-end">
              <button type="button" class="btn btn-success" (click)="addVariant()">Add Row <i
                class="fas fa-plus"></i></button>
            </div>
          </div>

          <div formArrayName="productInventoryDetails">

            <div *ngFor="let form of productInventoryDetails.controls; let i = index" [formGroupName]="i"
                 class="card mt-2 g-3">
              <div class="row mt-2 mb-2 g-2 ps-2 pe-2">

                <div class="col-md-4 col-lg-3 col-12">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      list="productList"
                      formControlName="productName"
                      (input)="selectCurrentProductName(i)"
                      placeholder="Select a Product Name"
                    />
                    <label class="form-label">Select a Product Name <span class="text-danger">*</span></label>
                    <datalist id="productList">
                      <option *ngFor="let pro of getProductDetails" [value]="pro?.name"></option>
                    </datalist>
                  </div>
                  <span class="text-danger" *ngIf="variantErrors[i]?.productName">
    {{ variantErrors[i]?.productName }}
  </span>
                </div>


                <div class="col-md-4 col-lg-3 col-12">
                  <div class="form-floating">
                    <input type="text" class="form-control" placeholder="Enter a Batch Code"
                           formControlName="batchCode">
                    <label class="form-label">Batch Code <span class="text-danger">*</span></label>
                  </div>
                  <span class="text-danger" *ngIf="variantErrors[i]?.batchCode">{{ variantErrors[i]?.batchCode }}</span>
                </div>

                <div class="col-md-4 col-lg-3 col-12">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="hsnCode" placeholder="Enter HSN/SAC Code"
                           formControlName="hsnCode" (input)="getGSTDetails()">
                    <label for="hsnCode">HSN/SAC Code<span class="text-danger">*</span></label>
                  </div>
                  <span class="text-danger" *ngIf="variantErrors[i]?.hsnCode">{{ variantErrors[i]?.hsnCode }}</span>
                </div>

                <div class="col-md-4 col-lg-3 col-12">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="pack" placeholder="Enter Pack"
                           formControlName="pack">
                    <label for="hsnCode">Pack<span class="text-danger">*</span></label>
                  </div>
                  <span class="text-danger" *ngIf="variantErrors[i]?.pack">{{ variantErrors[i]?.pack }}</span>
                </div>

                <div class="col-md-4 col-lg-3 col-12">
                  <div class="form-floating">
                    <input type="tel" class="form-control" placeholder="Enter Unit Price" formControlName="unitPrice"
                           (input)="updateTotalPrice()" oninput="this.value = this.value.replace(/\D/g, '')">
                    <label class="form-label">Purchase Rate<span class="text-danger">*</span></label>
                  </div>
                  <span class="text-danger" *ngIf="variantErrors[i]?.unitPrice">{{ variantErrors[i]?.unitPrice }}</span>
                </div>

                <div class="col-md-4 col-lg-3 col-12">
                  <div class="form-floating">
                    <input type="tel" class="form-control" placeholder="Enter a Quantity" formControlName="purchaseQty"
                           (input)="updateTotalPrice()" oninput="this.value = this.value.replace(/\D/g, '')">
                    <label class="form-label">Purchase Quantity <span class="text-danger">*</span></label>
                  </div>
                  <span class="text-danger" *ngIf="variantErrors[i]?.purchaseQty">{{ variantErrors[i]?.purchaseQty
                    }}</span>
                </div>


                <div class="col-md-4 col-lg-3 col-12">
                  <div class="form-floating">
                    <!-- <input type="tel" class="form-control" placeholder="Select a Expiry" formControlName="expiryDate">
                    <label class="form-label">Expiry Date <span class="text-danger">*</span></label>
                    </div> -->
                    <input class="form-control" type="text" placeholder="MM/YY" maxlength="5"
                           formControlName="expiryDate" (input)="formatExpiryDate($event,i)">
                    <label>Expiry Date</label>
                  </div>

                  <span class="text-danger" *ngIf="variantErrors[i]?.expiryDate">{{ variantErrors[i]?.expiryDate
                    }}</span>
                </div>

                <div class="col-md-4 col-lg-3 col-12">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="cgst" placeholder="Enter CGST" readonly
                           formControlName="cgst">
                    <label for="cgst">CGST(%)</label>
                  </div>
                </div>


                <div class="col-md-4 col-lg-3 col-12">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="sgst" placeholder="Enter SGST" readonly
                           formControlName="sgst">
                    <label for="sgst">SGST(%)</label>
                  </div>
                </div>

                <div class="col-md-4 col-lg-3 col-12">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="igst" placeholder="Enter IGST" readonly
                           formControlName="igst">
                    <label for="igst">IGST(%)</label>
                  </div>
                </div>

                <div class="col-md-4 col-lg-3 col-12">
                  <div class="form-floating">
                    <input type="text" class="form-control" placeholder="Enter a MRP Rate" formControlName="mrpRate"
                           oninput="this.value = this.value.replace(/\D/g, '')">
                    <label class="form-label">MRP Rate <span class="text-danger">*</span></label>
                  </div>
                  <span class="text-danger" *ngIf="variantErrors[i]?.mrpRate">{{ variantErrors[i]?.mrpRate }}</span>
                </div>


                <div class="col-md-4 col-lg-3 col-12">
                  <div class="form-floating">
                    <input class="form-control" placeholder="Enter a Discount %" formControlName="discount">
                    <label class="for-label">Discount %</label>
                  </div>
                </div>

                <div class="col-md-4 col-lg-3 col-12">
                  <div class="form-floating">
                    <input class="form-control" placeholder="Enter a Tax Amount" formControlName="taxAmount"
                           oninput="this.value = this.value.replace(/\D/g, '')">
                    <label class="for-label">Tax Amount</label>
                  </div>
                </div>

                <div class="col-md-4 col-lg-3 col-12">
                  <div class="form-floating">
                    <input class="form-control" placeholder="Enter a Total Amount" formControlName="totalPrice"
                           oninput="this.value = this.value.replace(/\D/g, '')">
                    <label class="for-label">Total Amount <span class="text-danger">*</span></label>
                  </div>
                  <span class="text-danger" *ngIf="variantErrors[i]?.totalPrice">{{ variantErrors[i]?.totalPrice
                    }}</span>
                </div>

              </div>

              <div class="row mt-2 mb-2">
                <div class="col-12 text-center">
                  <button type="button" class="btn btn-danger" (click)="removeVariant(i)">Delete Row <i
                    class="fas fa-trash"></i></button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row text-center mt-3 mb-5">
      <div class="col-12">
        <button type="submit" class="btn btn-primary">Save</button>
      </div>
    </div>

  </form>
</div>
