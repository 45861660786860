import { Component, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { Subject, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { LabTestDetails } from "@/model/labDetails/labTestDetails";
import { Router } from "@angular/router";
import * as CryptoJS from 'crypto-js';


@Component({
  selector: "app-lab-test-view",
  templateUrl: "./lab-test-view.component.html",
  styleUrls: ["./lab-test-view.component.scss"]
})
export class LabTestViewComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentDate: String;
  searchText: any;
  labTestDetails: LabTestDetails [] = [];
  allLabTestDetails: LabTestDetails[] = [];
  totalTestAmount: number = 0;
  hospitalShareTotalAmount: number = 0;
  labShareTotalAmount: number = 0;
  disabledStatus: { [labTestId: number]: boolean } = {};

  constructor(private appservice: AppService, private datePipe: DatePipe, private toastr: ToastrService,
    private spinner: NgxSpinnerService,private router: Router) {
    const currentDate = new Date();
    this.currentDate = this.datePipe.transform(currentDate, "yyyy-MM-dd") ?? "";
  }

  encryptData(data: any) {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret-key').toString();
    return encryptedData;
  }

  ngOnInit(): void {
    this.getAllLabTestDetails();
  }
 
  getAllLabTestDetails(){
    this.spinner.show();
    this.appservice.getAllLabTestDetails()
    .pipe(takeUntil(this.destroy$))
    .subscribe((data: any) => {
        this.spinner.hide();
        console.log("getLabDetails" ,data)
          this.allLabTestDetails = data.sort((a,b) => b.labTestId - a.labTestId);
          this.labTestDetails = this.allLabTestDetails;
          this.totalTestAmount = this.labTestDetails.reduce((total, item) => total + item.amount, 0)
          this.hospitalShareTotalAmount = this.labTestDetails.reduce((total, item) => total + item.amount/2 , 0)
          this.labShareTotalAmount = this.labTestDetails.reduce((total, item) => total + item.amount/2 , 0)
      },(error) => {
        this.spinner.hide();
        this.toastr.error(error.error.msg || "Something went wrong , please try again");
      }
    );
  }

  onDate(): void {
    const fromDate = (document.getElementById('fromDate') as HTMLInputElement).value;
    const toDate = (document.getElementById('toDate') as HTMLInputElement).value;
    
    if (fromDate && toDate) {
      const fromDateOnly = new Date(fromDate).setHours(0, 0, 0, 0);
      const toDateOnly = new Date(toDate).setHours(23, 59, 59, 999);
  
      this.labTestDetails = this.allLabTestDetails
        .filter(item => {
          const createdAtDate = new Date(item.createdAt).setHours(0, 0, 0, 0); 
          return createdAtDate >= fromDateOnly && createdAtDate <= toDateOnly;
        })
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        this.totalTestAmount = this.labTestDetails.reduce((total, item) => total + item.amount, 0)
        this.hospitalShareTotalAmount = this.labTestDetails.reduce((total, item) => total + item.amount/2 , 0)
        this.labShareTotalAmount = this.labTestDetails.reduce((total, item) => total + item.amount/2 , 0)
    } else {
      this.labTestDetails = this.allLabTestDetails;
    }
  }

  UpdatePaymentStatus(labTestId: number) {
    const value = labTestId
    for (let up of this.labTestDetails) {
      if (value == up.labTestId) {
        this.disabledStatus[value] = true;
        const jsonData = {
          labTestId: value,
          paymentStatus: "Paid"
        }
        this.updateLabPaymentStatus(jsonData);
      }
    }
  }

  updateLabPaymentStatus(jsonData : any) {
    this.spinner.show();
    this.appservice
      .updateLabPaymentDetails(jsonData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
      }, (err: any) => {
        console.log("Something Went Wrong")
        this.spinner.hide();
        this.toastr.error("Something went wrong , Please try again later....")
      });
  }
  
  print(labDetails : LabTestDetails){
    const encryptedData = this.encryptData(labDetails);
    this.router.navigate(['/lab-receipt-print', { data: encryptedData , label: "lab-table"}]);
  }

}
