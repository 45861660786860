import { AddProduct } from "@/model/medical-entry/add-product/add-product";
import { ProductInventoryDetails } from "@/model/medical-entry/product-main-inventory/product-inventory";
import { Component, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject, takeUntil } from "rxjs";
import * as XLSX from 'xlsx';
import * as CryptoJS from 'crypto-js';
import { Router } from "@angular/router";

@Component({
  selector: 'product-report-table',
  templateUrl: './product-report-table.component.html',
  styleUrls: ['./product-report-table.component.scss']
})
export class ProductReportTableComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  getListProductDetails: AddProduct[] = [];
  getProductDetails: AddProduct[] = [];
  productName: string = "";
  productType: string = "";
  productNameDetails: string[] = [];
  productTypeDetails: string[] = [];
  allStockDetails: ProductInventoryDetails[] = [];
  selectedProductName: string = "";
  searchText: any;
  isViewShow: boolean = false;
  private secretKey = "#EVOL-&$%$DF%SION*^%#^*F&%^DFSEYUJ";

  constructor(private appServices: AppService, private spinner: NgxSpinnerService,
    private toastr: ToastrService, private router: Router) {
  }

  ngOnInit() {
    this.getAllProductDetails();
  }


  getAllProductDetails() {
    this.spinner.show();
    this.appServices.getAllProduceDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.spinner.hide();
        console.log(res);
        this.getListProductDetails = res;
        this.productNameDetails = [...new Set(this.getListProductDetails.map(item => item.name))];
        this.productTypeDetails = [...new Set(this.getListProductDetails.map(item => item.type))];
        this.getProductDetails = this.getListProductDetails;
      }, (err: any) => {
        this.spinner.hide();
        this.toastr.error(err.error.message || "Something went wrong, Please try again later....");
      });
  }

  // Current select filter value set function
  updateDetails(event: any, filterType: string) {
    const value = event.target.value;

    switch (filterType) {
      case 'name':
        this.productName = value !== 'Select Product Name' ? value : 'all';
        break;
      case 'commodity':
        this.productType = value !== 'Select Product Type' ? value : 'all';
        break;

      default:
        break;
    }

    this.applyFilter();
  }

  // Apply filter function
  applyFilter() {
    let filteredData = [...this.getListProductDetails];

    // Apply product name filter
    if (this.productName && this.productName !== 'all') {
      filteredData = filteredData.filter(item => item.name === this.productName);
    }

    this.productTypeDetails = [...new Set(filteredData.map(item => item.type))];

    // Apply product type filter
    if (this.productType && this.productType !== 'all') {
      filteredData = filteredData.filter(item => item.type === this.productType);
    }

    this.getProductDetails = filteredData;
  }




  // Remove filter function values
  resetReviewFilter() {
    this.searchText = '';
    this.productName = '';
    this.productType = '';

    this.getProductDetails = [...this.getListProductDetails];
    this.productNameDetails = [...new Set(this.getListProductDetails.map(item => item.name))];
    this.productTypeDetails = [...new Set(this.getListProductDetails.map(item => item.type))];
  }

  //Download excel file
  downloadExcel() {
    // Generate worksheet data from order details
    const worksheetData = this.getProductDetails.map((report, index) => ({
      'S.No': index + 1,
      'Product Name': report.name,
      'Product Type': report.type,
      'Product Available Qty': report.productAvailQty,
    }));

    // Create the worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Filtered Reports');

    // Create the final filename
    const filename = `Product report, ${new Date().toLocaleDateString()}.xlsx`;

    // Check for issues with Excel file creation
    try {
      // Export workbook to Excel file
      XLSX.writeFile(workbook, filename);
      console.log('File successfully downloaded:', filename);
    } catch (error) {
      console.error('Error during file export:', error);
    }
  }


  close() {
    this.allStockDetails = [];
    this.isViewShow = false
  }

  viewDetails(id: number, productName: string) {
    this.selectedProductName = productName;
    this.spinner.show();
    this.appServices.getProductInventoryDetails(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.spinner.hide();
        this.allStockDetails = res;
        this.isViewShow = true;
      }, (err: any) => {
        this.spinner.hide();
        this.toastr.error(err.error.message || "Something went wrong, Please try again later....");
      });
  }

  editStock(id: number) {
    const encryptedBillId = CryptoJS.AES.encrypt(String(id), this.secretKey).toString();
    this.router.navigate(['/stock-adjustment'], { queryParams: { productId: encryptedBillId } });
  }

}


