<app-amdds-header></app-amdds-header>

<!-- <nav class="navbar navbar-expand-lg navbar-light mt-5" style="background-color:darkgray">
  <div class="container-fluid">
    <a class="navbar-brand">Anitha Medical</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mx-4 topnav" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto ">
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/add-product"><b>Add Product</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
          routerLink="/product-entry"><b>Product Purchase Entry</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/product-report"><b>Product Report</b></a>
        </li>
      </ul>
    </div>
  </div>
</nav> -->

<div class="container-fluid py-5">
  <form [formGroup]="addForm" (ngSubmit)="onSubmit()">
    <div class="row mt-2 mb-2 g-2 ps-2 pe-2">

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <div class="form-floating">
            <input type="text" class="form-control" placeholder="Enter a Product Name" formControlName="productName"
              readonly>
            <label class="form-label">Product Name <span class="text-danger">*</span></label>
          </div>
          <span class="text-danger" *ngIf="addFormError.productName">{{addFormError.productName}}</span>
        </div>
      </div>

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Enter a Batch Code" formControlName="batchCode">
          <label class="form-label">Batch Code <span class="text-danger">*</span></label>
        </div>
        <span class="text-danger" *ngIf="addFormError.batchCode">{{addFormError.batchCode}}</span>
      </div>

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input class="form-control" type="text" placeholder="MM/YY" maxlength="5" formControlName="expiryDate"
            (input)="formatExpiryDate($event)">
          <label>Expiry Date</label>
        </div>
        <span class="text-danger" *ngIf="addFormError.expiryDate">{{addFormError.expiryDate}}</span>
      </div>

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Enter a MRP Rate" formControlName="mrpRate"
            oninput="this.value = this.value.replace(/\D/g, '')">
          <label class="form-label">MRP Rate <span class="text-danger">*</span></label>
        </div>
        <span class="text-danger" *ngIf="addFormError.mrpRate">{{addFormError.mrpRate}}</span>
      </div>

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input type="tel" class="form-control" placeholder="Enter a Quantity" formControlName="purchaseQty"
            (input)="updateTotalPrice()" oninput="this.value = this.value.replace(/\D/g, '')">
          <label class="form-label">Purchase Quantity <span class="text-danger">*</span></label>
        </div>
        <span class="text-danger" *ngIf="addFormError.purchaseQty">{{addFormError.purchaseQty}}</span>
      </div>

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input type="tel" class="form-control" placeholder="Enter Unit Price" formControlName="unitPrice"
            (input)="updateTotalPrice()" oninput="this.value = this.value.replace(/\D/g, '')">
          <label class="form-label">Unit Price <span class="text-danger">*</span></label>
        </div>
        <span class="text-danger" *ngIf="addFormError.unitPrice">{{addFormError.unitPrice}}</span>
      </div>

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input class="form-control" placeholder="Enter a Discount %" formControlName="discount">
          <label class="for-label">Discount %</label>
        </div>
      </div>

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input class="form-control" placeholder="Enter a Tax Amount" formControlName="taxAmount"
            oninput="this.value = this.value.replace(/\D/g, '')">
          <label class="for-label">Tax Amount</label>
        </div>
      </div>

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input class="form-control" placeholder="Enter a Total Amount" formControlName="totalPrice"
            oninput="this.value = this.value.replace(/\D/g, '')">
          <label class="for-label">Total Amount <span class="text-danger">*</span></label>
        </div>
        <span class="text-danger" *ngIf="addFormError.totalPrice">{{addFormError.totalPrice}}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-12 col-12 text-center">
        <button type="submit" class="btn btn-primary">Save</button>
      </div>
    </div>

  </form>

</div>