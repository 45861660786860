<app-amdds-header></app-amdds-header>
<div class="container-fluid">
  <div class="row py-4"></div>
  <h3 class="ml-3 mt-4">Dashboard</h3>

  <div class="row">
    <div class="col-md-3" *ngIf="this.adminDisable">
      <a class="nav-link" routerLink="/NewJoinForm">
        <div class="small-box bg-info">
          <div class="row mt-1"></div>
          <div class="">
            <h4 class="mb-4 m-3 ">New Joining Form</h4>
          </div>
          <div class="row mt-2"></div>
        </div>
      </a>
    </div>
    <div class="col-md-3" *ngIf="this.adminDisable">
      <a class="nav-link" routerLink="/PatientCount">
        <div class="small-box bg-success">
          <div class="row mt-1"></div>
          <div class="">
            <h4 class="mb-4 m-3">OP Patient</h4>
          </div>
          <div class="row mt-2"></div>
        </div>
      </a>
    </div>
    <div class="col-md-3" *ngIf="userDisable">
      <a class="nav-link" routerLink="/StockUpload">
        <div class="small-box bg-warning">
          <div class="row mt-1"></div>
          <div class="">
            <h4 class="mb-4 m-3">Daily Stock Upload</h4>
          </div>
          <div class="row mt-2"></div>
        </div>
      </a>
    </div>
    <div class="col-md-3" *ngIf="userDisable">
      <a class="nav-link" routerLink="/StockList">
        <div class="small-box bg-secondary">
          <div class="row mt-1"></div>
          <div class="">
            <h4 class="mb-4 m-3">Daily Stock Details</h4>
          </div>
          <div class="row mt-2"></div>
        </div>
      </a>
    </div>

    <div class="col-md-3" *ngIf="userDisable">
      <a class="nav-link" routerLink="/StockExpiryDetails">
        <div class="small-box bg-info">
          <div class="row mt-1"></div>
          <div class="">
            <h4 class="mb-4 m-3">Expiry Stock Details</h4>
          </div>
          <div class="row mt-2"></div>
        </div>
      </a>
    </div>
    <div class="col-md-3" *ngIf="userDisable">
      <a class="nav-link" (click)="navgation()">
        <div class="small-box bg-success">
          <div class="row mt-1"></div>
          <div class="">
            <h4 class="mb-4 m-3">Vendor Payment</h4>
          </div>
          <div class="row mt-2"></div>
        </div>
      </a>
    </div>
    <div class="col-md-3" *ngIf="userDisable || scanDisable">
      <a class="nav-link" routerLink="/scan-center">
        <div class="small-box bg-warning">
          <div class="row mt-1"></div>
          <div class="">
            <h4 class="mb-4 m-3">Scan Details</h4>
          </div>
          <div class="row mt-2"></div>
        </div>
      </a>
    </div>
    <div class="col-md-3" *ngIf="userDisable || labDisable">
      <a class="nav-link" routerLink="/lab-test-form">
        <div class="small-box bg-secondary">
          <div class="row mt-1"></div>
          <div class="">
            <h4 class="mb-4 m-3">Lab Test Details</h4>
          </div>
          <div class="row mt-2"></div>
        </div>
      </a>
    </div>
  </div>
</div>
<!-- <script async="async" data-cfasync="false" src="//pl21298496.toprevenuegate.com/dd62b079ca34b6003becdf408c267efc/invoke.js"></script>    
            <div class="col-lg-8">
              <div class="card">
<div id="container-dd62b079ca34b6003becdf408c267efc"></div>
</div>
          </div> -->
<!-- </div> -->
<app-amdds-footer></app-amdds-footer>