import { Component, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { Subject, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { LabTestDetails } from "@/model/labDetails/labTestDetails";
import * as CryptoJS from 'crypto-js';
import { Router } from "@angular/router";
import Swal from "sweetalert2";

@Component({
  selector: "app-lab-test-form",
  templateUrl: "./lab-test-form.component.html",
  styleUrls: ["./lab-test-form.component.scss"]
})
export class LabTestFormComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentDate: String = "";
  selectedTests: any[] = [];
  doctorName : any [] = [];
  selectedTestAmount: number = 0;
  userType : string = "";
  labTestDetails : LabTestDetails;
  searchTerm;
  testList = [
    { name: 'CBC with ESR', price: 500 },
    { name: 'CBC only (TC & Dc ESR,HB & RBC,Platelet Count)', price: 400 },
    { name: 'Smear for Malarial Parasite', price: 300 },
    { name: 'Smear for Microfilaria', price: 300 },
    { name: 'B T & C T', price: 100 },
    { name: 'Blood Grouping', price: 100 },
    { name: 'RH Factor', price: 500 },
    { name: 'VDRL (STD)', price: 400 },
    { name: 'HIV', price: 800 },
    { name: 'Blood Widal', price: 100 },
    { name: 'F. Blood Sugar', price: 50 },
    { name: 'P.P. Blood Sugar', price: 50 },
    { name: 'R. Blood Sugar', price: 50 },
    { name: 'GTT', price: 500 },
    { name: 'Serum Cholesterol', price: 500 },
    { name: 'LDL', price: 500 },
    { name: 'HDL', price: 500 },
    { name: 'Triglycerides', price: 500 },
    { name: 'HBs Ag', price: 500 },
    { name: 'Serum Urea', price: 100 },
    { name: 'Serum Creatinine', price: 100 },
    { name: 'Serum Bilirubin', price: 300 },
    { name: 'SGOT & SGPT', price: 500 },
    { name: 'Alkaline Phosphatase', price: 500 },
    { name: 'Acid Phosphatase', price: 500 },
    { name: 'RA Test', price: 800 },
    { name: 'Mantoux Test', price: 100 },
    { name: 'Motion ova cyst', price: 300 },
    { name: 'Reducing Substances', price: 150 },
    { name: 'Urine', price: 300 },
    { name: 'ALB Sugar Deposits', price: 300 },
    { name: 'Complete Exam', price: 300 },
    { name: 'Pregnancy Test', price: 100 },
    { name: 'Culture & Sensitivity', price: 950 },
    { name: 'Culture & Sensitivity Urine', price: 750 },
    { name: 'Culture & Sensitivity Pus', price: 950 },
    { name: 'Culture & Sensitivity Blood', price: 1000 },
    { name: 'Semen Analysis', price: 1000 },
    { name: 'T3, T4 & TSH', price: 500 },
    { name: 'Free T3, T4 & TSH', price: 800 },
    { name: 'Dengue', price: 800 },
    { name: 'HCV', price: 300 },
    { name: 'TC', price: 150 },
    { name: 'HB', price: 100 },
    { name: 'P/T', price: 150 },
    { name: 'ESR', price: 100 },
  ];
  isDropdownOpen: boolean = false;

  constructor(private appService: AppService, private datePipe: DatePipe, private toastr: ToastrService,
    private spinner: NgxSpinnerService, private fb: FormBuilder,private router : Router) {
    const currentDate = new Date();
    this.currentDate = this.datePipe.transform(currentDate, "yyyy-MM-dd") ?? "";
  }

  encryptData(data: any) {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret-key').toString();
    return encryptedData;
  }

  addLabTestDetails = new UntypedFormGroup({
    patientName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    age: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    gender: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    phoneNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    labTestType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    amount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    referenceDoctor: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    reportreadyDateandtime : new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    doctorFees: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    updateAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
  })

  public addLabTestDetailsError = {
    patientName: "",
    age: "",
    gender: "",
    phoneNumber: "",
    amount: "",
    reportreadyDateandtime: "",
  }

  ngOnInit(): void {
    this.getAllName();
    this.userType = localStorage.getItem("userType");
  }

  getAllName() {
    this.appService
        .getAllDoctorNames()
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          console.log(data);
            this.doctorName = data.filter(d => d.doctorName);
        },(error : any) => {
          this.toastr.error(error.error.msg || "Something went wrong , please try again")
        })
}


  onSubmit(): void {
    this.formValidation();
  }

  formValidation() {
    this.addLabTestDetailsError.patientName = "";
    this.addLabTestDetailsError.age = "";
    this.addLabTestDetailsError.gender = "";
    this.addLabTestDetailsError.phoneNumber = "";
    this.addLabTestDetailsError.amount = "";
    this.addLabTestDetailsError.reportreadyDateandtime = "";
    let hasError = false;

    if (this.addLabTestDetails.get('patientName')?.invalid) {
      this.addLabTestDetailsError.patientName = "*Patient Name is required"
      this.addLabTestDetails.get('patientName')?.markAsTouched();
      hasError = true;
    }

    if (this.addLabTestDetails.get('age')?.invalid) {
      this.addLabTestDetailsError.age = "*Age is required"
      this.addLabTestDetails.get('age')?.markAsTouched();
      hasError = true;
    }

    if (this.addLabTestDetails.get('gender')?.invalid) {
      this.addLabTestDetailsError.gender = "*Gender is required"
      this.addLabTestDetails.get('gender')?.markAsTouched();
      hasError = true;
    }

    if (this.addLabTestDetails.get('phonreNumber')?.invalid) {
      this.addLabTestDetailsError.phoneNumber = "*Phone Number is required"
      this.addLabTestDetails.get('phoneNumber')?.markAsTouched();
      hasError = true;
    }

    if (this.addLabTestDetails.get('amount')?.invalid) {
      this.addLabTestDetailsError.amount = "*Amount is required"
      this.addLabTestDetails.get('amount')?.markAsTouched();
      hasError = true;
    }

    if(this.addLabTestDetails.get('reportreadyDateandtime')?.invalid){
      this.addLabTestDetailsError.reportreadyDateandtime = "*Report Ready Date and Time is required"
      this.addLabTestDetails.get('reportreadyDateandtime')?.markAsTouched();
      hasError = true;
    }

    if (!hasError) {
      if (this.selectedTests.length <= 0) {
        this.toastr.error("Please select lab test Type")
      } else {
        this.saveLabTestDetails();
      }
    }

  }
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  onCheckboxChange(event: Event, test: any) {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.selectedTests.push(test.name);
      this.selectedTestAmount += test.price;
      this.addLabTestDetails.controls['amount'].setValue(this.selectedTestAmount);
      console.log(this.selectedTests);
    } else {
      this.selectedTests = this.selectedTests.filter(name => name !== test.name);
      this.selectedTestAmount -= test.price;
      this.addLabTestDetails.controls['amount'].setValue(this.selectedTestAmount);
      console.log(this.selectedTestAmount);
    }
  }

  saveLabTestDetails() {
    this.spinner.show();
    this.addLabTestDetails.controls['labTestType'].setValue(this.selectedTests.join(', '));
    this.addLabTestDetails.controls['doctorFees'].setValue(this.selectedTestAmount / 2);
    console.log(this.addLabTestDetails.value);
    this.appService.saveLabTestDetails(this.addLabTestDetails.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.labTestDetails = data;
        this.addLabTestDetails.reset();
        this.selectedTests = [];
        this.selectedTestAmount = 0;
        this.addLabTestDetails.controls['labTestType'].setValue("");
        this.successAlert();
      }, (error: any) => {
        this.spinner.hide();
        this.toastr.error(error.error.msg || "Something went wrong , please try again")
        console.error(error);
      })
  }

  successAlert() {
    Swal.fire({
      title: "Lab Test Details Saved Successfully",
      confirmButtonText: "Ok",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        const encryptedData = this.encryptData(this.labTestDetails);
        this.router.navigate(['/lab-receipt-print', { data: encryptedData }]);
      }
    });
  }



}
