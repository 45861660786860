<div class="receipt">
  <div class="">
    <div class="row text-center">
      <div class="col-2">
        <img src="../../../assets/img/varshinihospital.png" alt="Logo"
             style="text-align: center;height: 150px;width: 150px;">
      </div>
      <div class="col-10 mt-3">
        <h4 class="text-uppercase text-bold mt-2 headerTitle">Varshini Hospital</h4>
        <div class="text-center headerSubTitle">
          #1, First Cross St, Sundar Nagar, Trichy 21
        </div>
        <h6 class="mt-2">****** www.varshinihospital.com ******</h6>
      </div>
    </div>
  </div>
  <hr>
  <div id="location">
    <div class="text-bold text-uppercase text-center location"><u>Lab Receipt</u></div>
  </div>

  <div class="row mt-3">
    <table class="table table-borderless">
      <thead>
      <tr>
        <th>No - {{labTestDetails.labTestId}}</th>
        <th class="text-end">Dated: {{labTestDetails.createdAt | date: 'MM-dd-yyyy / hh:mm a' }}</th>
      </tr>
      <tr>
        <td>Patient Name:</td>
        <td>{{labTestDetails.patientName}}</td>
      </tr>
      <tr>
        <td>Patient Age:</td>
        <td>{{labTestDetails.age}}</td>
      </tr>
      <tr>
        <td>Patient Gender:</td>
        <td>{{labTestDetails.gender}}</td>
      </tr>
      <tr>
        <td>Phone Number:</td>
        <td>{{labTestDetails.phoneNumber}}</td>
      </tr>
      <tr>
        <td>Lab Test Details:</td>
        <td>{{labTestDetails.labTestType}}</td>
      </tr>
      <tr class="amount">
        <td>Amount Received:</td>
        <td>
          <h5 class="price"> ₹ {{labTestDetails.amount }} /-</h5>
          <!-- <h5 class="price"> ₹ 100 /-</h5> -->
        </td>
      </tr>
      </thead>
    </table>
      <div class="col-12">
        <h1 class="fw-bold">Report will be ready by: {{transformDate(labTestDetails.reportreadyDateandtime)}}</h1>
      </div>
  </div>
  <!-- <div class="">
    <div class="row">
      <div class="col-3"></div>
      <div class="col-4 text-center">
        <img class="" src="{{vehicleQrCodeUrl}}" style="height: 500px; border: 4px solid #c54848">
      </div>
      <div class="col-4"></div>
    </div>
  </div> -->
  <div class="keepIt">
    <div class="row mt-4 mb-5">
      <div class="col-12 text-center keepIt">

        <!-- <h6 class="keepIt">Valid for single trip only</h6> -->
        <h5 class="keepIt font-italic">***** We wish you for your Speedy Recovery *****</h5>
      </div>
    </div>
  </div>
</div>