import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {AppService} from "@services/app.service";
import {Subject, takeUntil} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {AddStockDetails} from "@/model/addStockDetails";
import {DatePipe} from "@angular/common";
import {NgxSpinnerService} from "ngx-spinner";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import Swal from "sweetalert2";
import * as CryptoJS from 'crypto-js';
import {ScanCenterDetails} from "@/model/scenCenterDetails";
import {Router} from "@angular/router";

@Component({
  selector: "app-add-product",
  templateUrl: "./add-product.component.html",
  styleUrls: ["./add-product.component.scss"]
})
export class AddProductComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  medicalTypes: any[] = [];
  @ViewChild('nameInput') nameInput!: ElementRef;
  gstDetails: any;

  constructor(private apiService: AppService, private datePipe: DatePipe, private toastr: ToastrService,
              private spinner: NgxSpinnerService, private router: Router) {
  }

  addForm = new UntypedFormGroup({
    productId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    name: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    description: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    type: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    uom: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    productAvailQty: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    updatedAt: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    createdBy: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    sgst: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    cgst: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    igst: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    hsnCode: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
  })

  public addFormError = {
    name: '',
    type: '',
    hsnCode: '',
  }


  ngOnInit(): void {
    this.getAllProductType();
    setTimeout(() => {
      this.nameInput?.nativeElement.focus();
    }, 100);
  }

  encryptData(data: any) {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret-key').toString();
    return encryptedData;
  }

  onSubmit() {
    this.formValidation();
  }


  formValidation() {
    this.addFormError.name = "",
      this.addFormError.type = "";
    this.addFormError.hsnCode = "";
    let hasError = false;

    if (this.addForm.get('name')?.invalid) {
      this.addFormError.name = "*Product Name is required"
      this.addForm.get('name')?.markAsTouched();
      hasError = true;
    }

    if (this.addForm.get('type')?.invalid) {
      this.addFormError.type = "*Product Type is required"
      this.addForm.get('type')?.markAsTouched();
      hasError = true;
    }

    if (this.addForm.get('hsnCode')?.invalid) {
      this.addFormError.hsnCode = "*HSN Code is required"
      this.addForm.get('hsnCode')?.markAsTouched();
      hasError = true;
    }

    if (!hasError) {
      this.saveProduct();
    }

  }


  getAllProductType() {
    this.apiService.getAllProductType()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.medicalTypes = response;
        this.medicalTypes.sort(((a, b) => a.name.localeCompare(b.name)));
      }, (error) => {
        /*this.toastr.error(error.error.message || "Something went wrong, please try again..");*/
      });
  }


  saveProduct() {
    this.apiService.saveProductDetails(this.addForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.toastr.success("Product added successfully");
        this.addForm.reset();
      }, (error) => {
        this.toastr.error(error.error.message || "Something went wrong, please try again..");
      })
  }

  handleKeyDown(event: KeyboardEvent, currentField: string) {
    const formControls = ['name', 'type', 'description', 'hsnCode', 'sgst', 'cgst', 'igst'];
    let currentIndex = formControls.indexOf(currentField);

    if (event.key === 'Enter') {
      event.preventDefault();
      if (currentIndex < formControls.length - 1) {
        const nextControl = document.querySelector(`[formControlName="${formControls[currentIndex + 1]}"]`) as HTMLElement;
        nextControl?.focus();
      } else {
        this.onSubmit();
      }
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault();
      if (currentIndex < formControls.length - 1) {
        const nextControl = document.querySelector(`[formControlName="${formControls[currentIndex + 1]}"]`) as HTMLElement;
        nextControl?.focus();
      }
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (currentIndex > 0) {
        const prevControl = document.querySelector(`[formControlName="${formControls[currentIndex - 1]}"]`) as HTMLElement;
        prevControl?.focus();
      }
    }

    if (event.key === 'ArrowRight') {
      event.preventDefault();
      const inputs = Array.from(document.querySelectorAll('input, select')) as HTMLElement[];
      let index = inputs.findIndex(el => el.getAttribute('formControlName') === currentField);
      if (index !== -1 && index < inputs.length - 1) {
        inputs[index + 1]?.focus();
      }
    }

    if (event.key === 'ArrowLeft') {
      event.preventDefault();
      const inputs = Array.from(document.querySelectorAll('input, select')) as HTMLElement[];
      let index = inputs.findIndex(el => el.getAttribute('formControlName') === currentField);
      if (index !== -1 && index > 0) {
        inputs[index - 1]?.focus();
      }
    }

    if (event.key === 'F8') {
      event.preventDefault();
      this.dashboardPopup();
    }
  }


  dashboardPopup() {
    Swal.fire({
      title: 'Dashboard',
      text: 'Are you sure you want to go e?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/dash']);
      }
    })
  }

  saveFunctionPopup() {
    Swal.fire({
      title: 'Save',
      text: 'Are you sure you want to save this product?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.onSubmit();
      }
    })
  }

  getGSTDetails() {
    const hsnCode = this.addForm.get('hsnCode')?.value;

    if (!hsnCode) {
      this.toastr.warning("Please enter a valid HSN Code");
      return;
    }

    this.apiService.getGSTDetailsByHSNCode(hsnCode)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.gstDetails = response;
          console.log(`GST Data:`, this.gstDetails);
          this.addForm.patchValue({
            cgst: this.gstDetails.cgst || 0,
            sgst: this.gstDetails.sgst || 0,
            igst: this.gstDetails.igst || 0
          });
        },
        error: (err: any) => {
          console.error("Error fetching GST details:", err);
         /* this.toastr.error(err.error?.message || "Something went wrong, please try again later.");*/
        },
        complete: () => this.spinner.hide() // Hide spinner when request completes
      });
  }

}
