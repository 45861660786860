<app-amdds-header></app-amdds-header>

<!--<nav class="navbar navbar-expand-lg navbar-light mt-5" style="background-color:darkgray">
  <div class="container-fluid">
    <a class="navbar-brand">Anitha Medical</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mx-4 topnav" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto ">
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/add-product"><b>Add Product</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
          routerLink="/product-entry"><b>Product Purchase Entry</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/product-report"><b>Product Report</b></a>
        </li>
      </ul>
    </div>
  </div>
</nav>-->

<div class="container-fluid py-3">
  <div class="py-5"></div>

  <form [formGroup]="addForm" (ngSubmit)="onSubmit()">
    <div class="row g-2">

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input type="text" class="form-control" id="productName" placeholder="Enter a Product Name"
            formControlName="name" (keydown)="handleKeyDown($event, 'name')" #nameInput>
          <label for="productName">Product Name <span class="text-danger">*</span></label>
        </div>
        <span class="text-danger" *ngIf="addFormError.name">{{ addFormError.name }}</span>
      </div>

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input
            list="productTypes"
            class="form-control"
            id="product"
            formControlName="type"
            (keydown)="handleKeyDown($event, 'type')"
            placeholder="Select Product Type"
          />
          <label for="product">Product Type <span class="text-danger">*</span></label>
          <datalist id="productTypes">
            <option *ngFor="let type of medicalTypes" [value]="type.name"></option>
          </datalist>
        </div>
        <span class="text-danger" *ngIf="addFormError.type">{{ addFormError.type }}</span>
      </div>


      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input type="text" class="form-control" id="description" placeholder="Enter a Description"
            formControlName="description" (keydown)="handleKeyDown($event, 'description')">
          <label for="description">Product Description</label>
        </div>
      </div>

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input type="text" class="form-control" id="hsnCode" placeholder="Enter HSN/SAC Code"
                 formControlName="hsnCode" (keydown)="handleKeyDown($event, 'hsnCode')" (input)="getGSTDetails()">
          <label for="hsnCode">HSN/SAC Code<span class="text-danger">*</span></label>
        </div>
        <span class="text-danger" *ngIf="addFormError.hsnCode">{{ addFormError.hsnCode }}</span>
      </div>

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input type="tel" class="form-control" id="sgst" placeholder="Enter SGST" formControlName="sgst"
            (keydown)="handleKeyDown($event, 'sgst')">
          <label for="sgst">SGST %</label>
        </div>
      </div>

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input type="tel" class="form-control" id="cgst" placeholder="Enter CGST" formControlName="cgst"
            (keydown)="handleKeyDown($event, 'cgst')">
          <label for="cgst">CGST %</label>
        </div>
      </div>

      <div class="col-md-4 col-lg-3 col-12">
        <div class="form-floating">
          <input type="tel" class="form-control" id="igst" placeholder="Enter IGST" formControlName="igst"
            (keydown)="handleKeyDown($event, 'igst')">
          <label for="igst">IGST %</label>
        </div>
      </div>

    </div>

    <div class="row text-center mt-3">
      <div class="col-12">
        <button type="submit" class="btn btn-primary" (keydown)="handleKeyDown($event, 'submit')">Add Product</button>
      </div>
    </div>

  </form>
</div>
