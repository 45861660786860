import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NewJoinForms } from "@/model/new_JoinForms";
import { Form, FormGroup, UntypedFormGroup } from "@angular/forms";
import { PatientCount } from "@/model/PatientCount";
import { DoctorName } from "@/model/DoctorName";
import { dailyDoctors } from "@/model/dailyDoctors";
import { AddStockDetails } from "@/model/addStockDetails";
import { StockPurchaseDetails } from '@/model/stockPurchaseDetails';
import { addVendor } from '@/model/addVendor';
import { LoginRequest } from '@/model/login-request';
import { LoginResponseModel } from '@/model/login-response-model';
import { LocalDataService } from './local-data.service';
import { vendorDetails } from '@/model/vendorDetails';
import { ScanCenterDetails } from '@/model/scenCenterDetails';
import { scanDoctorPayments } from '@/model/scanDoctorPayments';
import { LabTestDetails } from '@/model/labDetails/labTestDetails';
import { ConfigService } from './config-service/config-service';
import { AddProduct } from '@/model/medical-entry/add-product/add-product';
import { ProductMainInventory } from '@/model/medical-entry/product-main-inventory/product-main-inventory';
import { ProductInventoryDetails } from '@/model/medical-entry/product-main-inventory/product-inventory';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public user: any = null;
  userDetails: LoginResponseModel
  //  endpoint = "http://localhost:8085"
  //  endpoint = "http://136.233.82.212:8080/amdds-ws"

  constructor(private router: Router, private toastr: ToastrService, private http: HttpClient,
    private localData: LocalDataService,private configService : ConfigService) {
  }

  // async loginByAuth({email, password}) {
  //   try {
  //     const token = await Gatekeeper.loginByAuth(email, password);
  //     localStorage.setItem('token', token);
  //     await this.getProfile();
  //     this.router.navigate(['/']);
  //     this.toastr.success('Login success');
  //   } catch (error) {
  //     this.toastr.error(error.message);
  //   }
  // }

  //Old Amdds Login API
  async loginByAuth(loginform: any) {
    const username = loginform.get("username").value;
    const password = loginform.get("password").value;

    let loginRequest = new LoginRequest();
    loginRequest.username = username;
    loginRequest.password = password;

    try {
      const add_url = this.configService.getConfig().END_POINT2 + "/Amdds/auth/login";
      const body = JSON.stringify(loginRequest);
      const headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };

      let status = 502;
      this.user = await this.http
        .post<LoginResponseModel>(add_url, body, headers)
        .subscribe(
          (data) => {
            if (data != null) {
              localStorage.setItem("authHeader", String(data.jwtToken));
              localStorage.setItem("userType", String(data.loginDetails.userType));
              this.router.navigate(["dash"]);
              this.toastr.success("Login Successfully");
            }
          },
          (error) => {
            console.error(error);
            this.toastr.error("User Name or Password is Wrong");
          }
        );
    } catch (error) {
      console.log(error);
      this.toastr.error("Network Error.Try again Later");
    }
  }


  //New Amdds login API
  async loginAuth(loginform: any) {
    const username = loginform.get("username").value;
    const password = loginform.get("password").value;

    let loginRequest = new LoginRequest();
    loginRequest.username = username;
    loginRequest.password = password;

    try {
      const add_url = this.configService.getConfig().END_POINT1 + "/auth/user/login";
      const body = JSON.stringify(loginRequest);
      const headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };

      let status = 502;
      this.user = await this.http
        .post<LoginResponseModel>(add_url, body, headers)
        .subscribe(
          (data) => {
            if (data != null) {
              localStorage.setItem("authHeader1", String(data.authHeader));
              localStorage.setItem("userType1", String(data.loginDetails.userType));
              // this.router.navigate(["dash"]);
              // this.toastr.success("Login Successfully");
            }
          })
  }catch (error) {
    console.log(error);
    this.toastr.error("Network Error.Try again Later");
  }
}


  async registerByAuth({ email, password }) {
    try {
      // const token = await Gatekeeper.registerByAuth(email, password);
      const token = String(this.localData.getHeaders())
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Register success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async loginByGoogle() {
    try {
      // const token = await Gatekeeper.loginByGoogle();
      const token = String(this.localData.getHeaders())
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Login success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async registerByGoogle() {
    try {
      // const token = await Gatekeeper.registerByGoogle();
      const token = String(this.localData.getHeaders())
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Register success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async loginByFacebook() {
    try {
      // const token = await Gatekeeper.loginByFacebook();
      const token = String(this.localData.getHeaders())
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Login success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async registerByFacebook() {
    try {
      // const token = await Gatekeeper.registerByFacebook();
      const token = String(this.localData.getHeaders())
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['']);
      this.toastr.success('Register success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async getProfile() {
    try {
      // this.user = await Gatekeeper.getProfile();
      this.user = String(this.localData.getHeaders())
    } catch (error) {
      this.logout();
      throw error;
    }
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    localStorage.removeItem('gatekeeper_token');
    localStorage.removeItem('authHeader');
    localStorage.removeItem('newJoiningId');
    localStorage.removeItem('employeeType');
    localStorage.removeItem('newEmploeeName');
    this.user = null;
    this.router.navigate(['/']);
  }

  /*------------------------------- Old Varshini Hospital API Details -------------------------------------------------*/

  getJoinDetails() {
    return this.http.get<NewJoinForms[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/getAllEmployees",
      { headers: this.localData.getHeaders() }
    )
  }

  /* get New Join ID */

  getJoinID() {
    return this.http.get<NewJoinForms[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/getnewJoinId",
      { headers: this.localData.getHeaders() }
    )
  }

  /*Save NewJoining Details */

  saveNewJoining(userForms: FormGroup) {
    return this.http.post<NewJoinForms[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/newJoinForms", userForms,
      { headers: this.localData.getHeaders() }
    )
  }

  fileUpload(formData: FormData, NewJoinId: string, FileName: string) {
    return this.http.post<String[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/fileUpload/upload?newjoin_id=" + NewJoinId + "&fileName=" + FileName, formData,
      { headers: this.localData.getHeaders() }
    )
  }

  fileGet(id: string, fileName: string) {
    return this.http.get(
     this.configService.getConfig().END_POINT2 + "/Amdds/getDocument/" + id + "/" + fileName,
      { headers: this.localData.getHeaders(), responseType: 'blob' }
    )
  }
  getDoctorId() {
    return this.http.get<PatientCount[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/getDoctorId",
      { headers: this.localData.getHeaders() }
    )
  }

  addBulkDetails(userForm: FormGroup) {
    return this.http.post<PatientCount[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/addPatientBulk", userForm,
      { headers: this.localData.getHeaders() }
    )
  }

  saveNewDoctor(userForms: FormGroup) {
    return this.http.post<DoctorName>(
     this.configService.getConfig().END_POINT2 + "/Amdds/addDoctor", userForms,
      { headers: this.localData.getHeaders() }
    )
  }

  getAllDoctorNames() {
    return this.http.get<DoctorName[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/getDoctor",
      { headers: this.localData.getHeaders() }
    )
  }

  getAllPatientDeatils() {
    return this.http.get<PatientCount[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/getPatient",
      { headers: this.localData.getHeaders() }
    )
  }

  getDateFetch() {
    return this.http.get<PatientCount>(
     this.configService.getConfig().END_POINT2 + "/Amdds/getPatient",
      { headers: this.localData.getHeaders() }

    );
  }

  getDailyDoctor(Pdate: string) {
    return this.http.get<dailyDoctors[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/getDailyDoctor/" + Pdate,
      { headers: this.localData.getHeaders() }
    )
  }

  addStockDetails(stock: any) {
    return this.http.post<AddStockDetails[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/addDailyStocks", stock,
      { headers: this.localData.getHeaders() }
    )
  }

  getDailyStockDetails(date: string) {
    return this.http.get<AddStockDetails[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/getDailyStockDetails/" + date,
    )
  }

  excelFileUpload(formData: any) {
    return this.http.post<StockPurchaseDetails[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/api/hospital_stock/addAll", formData,
      { headers: this.localData.getHeaders() }
    )
  }

  gethospitalStock() {
    return this.http.get<StockPurchaseDetails[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/api/hospital_stock/getAll",
      { headers: this.localData.getHeaders() }
    )
  }
  updateAdminStatus(stockId: number) {
    return this.http.put<StockPurchaseDetails>(
     this.configService.getConfig().END_POINT2 + "/Amdds/api/hospital_stock/updateAdminStatus/ " + stockId, '',
      { headers: this.localData.getHeaders() }
    )
  }

  saveVendor(usersform: UntypedFormGroup) {
    return this.http.post<addVendor[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/api/vendor/add", usersform,
      { headers: this.localData.getHeaders() }
    );
  }

  updateDocterStatus(stockId: number) {
    return this.http.put<StockPurchaseDetails>(
     this.configService.getConfig().END_POINT2 + "/Amdds/api/hospital_stock/update_doctor_status/" + stockId, '',
      { headers: this.localData.getHeaders() }
    )
  }

  updateAmoutDocterStatus(usersform: any) {
    return this.http.put<StockPurchaseDetails>(
     this.configService.getConfig().END_POINT2 + "/Amdds/api/hospital_stock/update_doctor_status/", usersform,
      { headers: this.localData.getHeaders() }
    )
  }

  getAgingDetail() {
    return this.http.get<StockPurchaseDetails[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/api/hospital_stock/getByAging"
    )
  }

  updateTransactionID(form: FormGroup) {
    return this.http.put<StockPurchaseDetails>(
     this.configService.getConfig().END_POINT2 + "/Amdds/api/hospital_stock/updateTransactionDetails", form,
      { headers: this.localData.getHeaders() }
    )
  }

  getSameStockDetails() {
    return this.http.get<AddStockDetails[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/getDailyStockDetails/getLast7days",
      { headers: this.localData.getHeaders() }
    )
  }

  //bulkUpdate AdminStatus in oldInvoice

  updateBulkStatus(userForm: number[]) {
    return this.http.put<StockPurchaseDetails[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/api/hospital_stock/updateAdminStatus/bulk", userForm,
      { headers: this.localData.getHeaders() }
    )
  }

  //Get Hospital Vendor Details

  getVendorDetails() {
    return this.http.get<vendorDetails[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/api/vendor/getAll",
      { headers: this.localData.getHeaders() }
    )
  }

  //Description Based Details Get

  getAmDailyDescripyion(description: string) {
    return this.http.get<AddStockDetails[]>(
     this.configService.getConfig().END_POINT2 + "/Amdds/getOtherDailyStockDetails/" + description,
    )
  }

  //Save Scan Details
  saveScanDetails(userForm: any) {
    return this.http.post<ScanCenterDetails>(
     this.configService.getConfig().END_POINT2 + "/api/scan-details/save", userForm,
      { headers: this.localData.getHeaders() }
    )
  }

  getScanDetailsDateBased(date: string) {
    return this.http.get<ScanCenterDetails[]>(
     this.configService.getConfig().END_POINT2 + "/api/scan-details/getByDate/" + date,
      { headers: this.localData.getHeaders() }
    )
  }

  getAllScanDetails() {
    return this.http.get<ScanCenterDetails[]>(
     this.configService.getConfig().END_POINT2 + "/api/scan-details/getAll",
      { headers: this.localData.getHeaders() }
    )
  }

  saveScanPaymentDetails(userForm: any) {
    return this.http.post<scanDoctorPayments[]>(
     this.configService.getConfig().END_POINT2 + "/api/doctor-fees/scan-details/save", userForm,
      { headers: this.localData.getHeaders() }
    )
  }

  getScanDoctorPayment() {
    return this.http.get<scanDoctorPayments[]>(
     this.configService.getConfig().END_POINT2 + "/api/doctor-fees/scan-details/getAll",
      { headers: this.localData.getHeaders() }
    )
  }

  getScanDoctorPaymentByDate(date: string) {
    return this.http.get<scanDoctorPayments[]>(
     this.configService.getConfig().END_POINT2 + "/api/doctor-fees/scan-details/getByPaidDate/" + date,
      { headers: this.localData.getHeaders() }
    )
  }

  //Save Lab Test Details

  saveLabTestDetails(formData: FormGroup) {
    return this.http.post<LabTestDetails>(
     this.configService.getConfig().END_POINT2 + "/api/labtest/save", formData,
      { headers: this.localData.getHeaders() }
    )
  }

  //Get All Lab Test Details

  getAllLabTestDetails() {
    return this.http.get<LabTestDetails[]>(
     this.configService.getConfig().END_POINT2 + "/api/labtest/getAll",
      { headers: this.localData.getHeaders() }
    )
  }

  //Update Lab Test Payment Details

  updateLabPaymentDetails(jsonData: any) {
    return this.http.put(
     this.configService.getConfig().END_POINT2 + "/api/labtest/update", jsonData,
      { headers: this.localData.getHeaders() }
    )
  }

  //Update Scan Payment Details

  updateScanPaymentStatus(jsonData: any) {
    return this.http.put(
     this.configService.getConfig().END_POINT2 + "/api/scan-details/updatePaymentStatus", jsonData,
      { headers: this.localData.getHeaders() }
    )
  }

  // Vendor Id based details get

  getVendorIdDetails(vendorId: number) {
    return this.http.get<addVendor>(
     this.configService.getConfig().END_POINT2 + "/Amdds/api/vendor/getById/" + vendorId,
      { headers: this.localData.getHeaders() }
    )
  }

  //Update vendor details

  updateVendorDetails(formData: FormGroup) {
    return this.http.put<addVendor>(
     this.configService.getConfig().END_POINT2 + "/Amdds/api/vendor/update", formData,
      { headers: this.localData.getHeaders() }
    )
  }

/*------------------------------- New Feature For Anitha Medical & Varshini Changes based API Details -------------------------------------------------*/

    //Product category type details get all
    getAllProductType(){
      return this.http.get<any[]>(
        this.configService.getConfig().END_POINT1 + "/api/medicine-type/get-all",
      )
    }

    //Save product details
    saveProductDetails(formData: any) {
      return this.http.post<AddProduct>(
        this.configService.getConfig().END_POINT1 + "/api/product", formData,
        { headers: this.localData.getNewHeaders() }
      )
    }

    //get all product details
    getAllProduceDetails(){
      return this.http.get<AddProduct[]>(
        this.configService.getConfig().END_POINT1 + "/api/product",
        { headers : this.localData.getNewHeaders()}
      )
    }

    //get all vendor details
    getAllVendorDetailsByNew(){
      return this.http.get<addVendor[]>(
        this.configService.getConfig().END_POINT1 + "/api/vendor/get-all",
        { headers : this.localData.getNewHeaders()}
      )
    }

    //Save product Inventory details
    saveProductInventoryDetails(formData: any) {
      return this.http.post<ProductMainInventory>(
        this.configService.getConfig().END_POINT1 + "/api/product/inward", formData,
        { headers: this.localData.getNewHeaders() }
      )
    }

    //New vendor personal & bank account details save api
    saveVendorDetails(formData : FormGroup){
      return this.http.post<addVendor>(
        this.configService.getConfig().END_POINT1 + "/api/vendor" , formData,
        { headers : this.localData.getNewHeaders() }
      )
    }

    //Update vendor personal & bank account details api
    updateVendorBankDetails(formData : FormGroup){
      return this.http.put<addVendor>(
        this.configService.getConfig().END_POINT1 + "/api/vendor", formData,
        { headers : this.localData.getNewHeaders() }
      )
    }

    //vendor details get by id
    vendorDetailsGetById(vendorId : number){
      return this.http.get<addVendor>(
        this.configService.getConfig().END_POINT1 + "/api/vendor/" + vendorId,
        { headers : this.localData.getNewHeaders()}
      )
    }

    //Vendor details existing check api
    vendorNameExistingCheck(vendorName : string){
      return this.http.get<any>(
        this.configService.getConfig().END_POINT1 + "/api/vendor/check-name/" + vendorName,
        { headers : this.localData.getNewHeaders()}
      )
    }

    //Product id based inventory details get
    getProductInventoryDetails(productId : number){
      return this.http.get<ProductInventoryDetails[]>(
        this.configService.getConfig().END_POINT1 + "/api/product/inventory/get-by-id/" + productId,
        { headers : this.localData.getNewHeaders()}
      )
    }

    //Product batch id based inventory details get
    getBatchIdBasedInventoryDetails(batchId : number){
      return this.http.get<ProductInventoryDetails>(
        this.configService.getConfig().END_POINT1 + "/api/product/inventory/" + batchId,
        { headers : this.localData.getNewHeaders()}
      )
    }

    //Update stock inventory details
    updateInventoryDetails(formData : FormData){
      return this.http.put<ProductInventoryDetails>(
        this.configService.getConfig().END_POINT1 + "/api/product/inventory", formData,
        { headers : this.localData.getNewHeaders(), observe:'response' }
      )
    }

    //Get all current stock details
    getAllCurrentStockDetails(){
      return this.http.get<ProductInventoryDetails[]>(
        this.configService.getConfig().END_POINT1 + "/api/product/inventory/get-all" ,
        { headers : this.localData.getNewHeaders()}
      )
    }

    //Get all vendors stock purchase details
    getAllPurchaseStockDetails(){
      return this.http.get<StockPurchaseDetails[]>(
        this.configService.getConfig().END_POINT1 + "/api/vendor-payment/getByAging",
        { headers : this.localData.getNewHeaders()}
      )
    }

    //The vendor payment admin approval update
    updateCurrentAdminStatus(stockId: number) {
      return this.http.put<StockPurchaseDetails>(
       this.configService.getConfig().END_POINT1 + "/api/vendor-payment/updateAdminStatus/" + stockId, '',
        { headers: this.localData.getNewHeaders() }
      )
    }

    //The vendor payment admin bulk approval
    updateAdminBulkStatus(userForm: number[]) {
      return this.http.put<StockPurchaseDetails[]>(
       this.configService.getConfig().END_POINT1 + "/api/vendor-payment/updateAdminStatus/bulk", userForm,
        { headers: this.localData.getNewHeaders() }
      )
    }

    //Vendor purchase cn amount update
    updatePurchaseCNAmount(usersform: any) {
        return this.http.put<StockPurchaseDetails>(
         this.configService.getConfig().END_POINT1 + "/api/vendor-payment/update_doctor_status", usersform,
          { headers: this.localData.getNewHeaders() }
        )
      }

  //Vendor purchase payment transaction details update
  updateVendorPurchaseTransactionDetails(form: FormGroup){
      return this.http.put<StockPurchaseDetails>(
       this.configService.getConfig().END_POINT1 + "/api/vendor-payment/updateTransactionDetails", form,
        { headers: this.localData.getNewHeaders() }
      )
    }

    //Vendor invoice bill no check
    checkVendorInvoiceBillNo(form : any){
      return this.http.post(
        this.configService.getConfig().END_POINT1 + "/api/vendor-payment/check-invoice" , form,
        { headers: this.localData.getNewHeaders() }
      )
    }


    //get gst details by HSN code
    getGSTDetailsByHSNCode(hsnCode: any) {
      return this.http.get<any>(
        this.configService.getConfig().END_POINT1 + `/api/product/gst-details/${hsnCode}`,
        { headers: this.localData.getNewHeaders() }
      )
    }

  }
