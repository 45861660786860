import { Component, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { Subject, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import * as CryptoJS from 'crypto-js';
import { ActivatedRoute, Router } from "@angular/router";
import { ProductInventoryDetails } from "@/model/medical-entry/product-main-inventory/product-inventory";

@Component({
  selector: "app-stock-adjustment-form",
  templateUrl: "./stock-adjustment-form.component.html",
  styleUrls: ["./stock-adjustment-form.component.scss"]
})
export class StockAdjustmentComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  medicalTypes: any[] = [];
  private secretKey = "#EVOL-&$%$DF%SION*^%#^*F&%^DFSEYUJ";
  productId: number = 0;
  allStockDetails: ProductInventoryDetails;

  constructor(private appServices: AppService, private datePipe: DatePipe, private toastr: ToastrService,
    private spinner: NgxSpinnerService, private router: Router, private route: ActivatedRoute,) {
  }

  addForm = new UntypedFormGroup({
    productBatchId: new UntypedFormControl(0, [Validators.required]),
    productId: new UntypedFormControl(0, [Validators.required]),
    inventoryId: new UntypedFormControl(0, [Validators.required]),
    addedDate: new UntypedFormControl(0, [Validators.required]),
    batchCode: new UntypedFormControl("", [Validators.required]),
    productName: new UntypedFormControl("", [Validators.required]),
    expiryDate: new UntypedFormControl("", [Validators.required, Validators.pattern(/^(0[1-9]|1[0-2])\/\d{2}$/)]),
    mrpRate: new UntypedFormControl("", [Validators.required]),
    purchaseQty: new UntypedFormControl("", [Validators.required]),
    unitPrice: new UntypedFormControl("", [Validators.required]),
    taxAmount: new UntypedFormControl("", [Validators.required]),
    totalPrice: new UntypedFormControl("", [Validators.required]),
    discount: new UntypedFormControl("", [Validators.required]),
    createdAt: new UntypedFormControl("", [Validators.required]),
    createdBy: new UntypedFormControl("", [Validators.required]),
    updatedAt: new UntypedFormControl("", [Validators.required]),
  });

  public addFormError = {
    productName: '',
    batchCode: '',
    expiryDate: '',
    mrpRate: '',
    purchaseQty: '',
    unitPrice: '',
    totalPrice: '',
  }


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['productId']) {
        const decryptedProductId = CryptoJS.AES.decrypt(params['productId'], this.secretKey).toString(CryptoJS.enc.Utf8);
        this.productId = Number(decryptedProductId);

      }
    });
    this.fetchInventoryDetails(this.productId);
  }

  fetchInventoryDetails(productId: number) {
    this.appServices.getBatchIdBasedInventoryDetails(productId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.spinner.hide();
        this.allStockDetails = res;
        this.addForm.controls['productName'].setValue(res.productDetails.name)
        this.addForm.patchValue(res);
      }, (err: any) => {
        this.spinner.hide();
        this.toastr.error(err.error.message || "Something went wrong, Please try again later....");
      });
  }

  onSubmit() {
    this.formValidation();
  }


  formValidation() {
    this.addFormError.productName = "";
    this.addFormError.batchCode = "";
    this.addFormError.expiryDate = "";
    this.addFormError.mrpRate = "";
    this.addFormError.purchaseQty = "";
    this.addFormError.unitPrice = "";
    this.addFormError.totalPrice = "";

    let hasError = false;

    if (this.addForm.get('productName')?.invalid) {
      this.addFormError.productName = "*Product Name is required"
      this.addForm.get('productName')?.markAsTouched();
      hasError = true;
    }

    if (this.addForm.get('batchCode')?.invalid) {
      this.addFormError.batchCode = "*Batch Code is required"
      this.addForm.get('batchCode')?.markAsTouched();
      hasError = true;
    }

    if (this.addForm.get('expiryDate')?.invalid) {
      this.addFormError.expiryDate = "*Expiry Date is required"
      this.addForm.get('expiryDate')?.markAsTouched();
      hasError = true;
    }

    if (this.addForm.get('mrpRate')?.invalid) {
      this.addFormError.mrpRate = "*MRP Rate is required"
      this.addForm.get('mrpRate')?.markAsTouched();
      hasError = true;
    }

    if (this.addForm.get('purchaseQty')?.invalid) {
      this.addFormError.purchaseQty = "*Purchase Quantity is required"
      this.addForm.get('purchaseQty')?.markAsTouched();
      hasError = true;
    }

    if (this.addForm.get('unitPrice')?.invalid) {
      this.addFormError.unitPrice = "*Unit Price is required"
      this.addForm.get('unitPrice')?.markAsTouched();
      hasError = true;
    }

    if (this.addForm.get('totalPrice')?.invalid) {
      this.addFormError.totalPrice = "*Total Amount is required"
      this.addForm.get('totalPrice')?.markAsTouched();
      hasError = true;
    }

    if (!hasError) {
      this.updateInventoryDetails();
    }

  }

  formatExpiryDate(event: any,) {
    let inputValue = event.target.value.replace(/\D/g, '');
    if (inputValue.length > 2) {
      inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(2, 4)}`;
    }
    event.target.value = inputValue;

    // Set formatted value in the corresponding form control
    this.addForm.controls['expiryDate'].setValue(inputValue, { emitEvent: false });

  }

  updateTotalPrice() {
    const totalPrice = this.addForm.get('purchaseQty')?.value * this.addForm.get('unitPrice')?.value;
    this.addForm.controls['totalPrice'].setValue(totalPrice);
  }


  //Inventory update function
  updateInventoryDetails() {
    this.spinner.show();
    this.appServices.updateInventoryDetails(this.addForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.spinner.hide();
        this.toastr.success("Stock Adjustment updated successfully.");
        this.router.navigate(['/product-report']);
      }, (err: any) => {
        this.spinner.hide();
        this.toastr.error(err.error.message || "Something went wrong, Please try again later....");
      });
  }



}
