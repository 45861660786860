<app-amdds-header></app-amdds-header>
<nav class="navbar navbar-expand-lg navbar-light mt-5" style="background-color:darkgray">
  <div class="container-fluid">
    <a class="navbar-brand">Scan Details</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mx-4 topnav" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto ">
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/scan-center"><b>Scan Entry</b></a>
        </li>
        <li class="nav-item" *ngIf="this.userType != 'SCAN' ">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/scan-center-payment"><b>Scan Payment Details</b></a>
        </li>
        <li class="nav-item" *ngIf="this.userType != 'SCAN' ">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/scan-center-doctor-payment"><b>Scan Doctor Payment</b></a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="container-fluid">
  <div class="row py-1"></div>
  <!-- <h3 class="ml-3 mt-4">Stock Upload</h3> -->
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card">
        <form (ngSubmit)="onSubmit()" [formGroup]="scanForm">
          <div class="card-body">
            <div class="row">
              <div class="col-md-9"></div>
              <div class="col-md-3">
                <div class="form-floating">
                  <input type="date" formControlName="scanDate" class="form-control" [max]="currentDate" readonly>
                  <label>Date <span style="color:red;">*</span></label>
                </div>
                <span *ngIf="scanFormError.scanDate" class="text-danger">{{ scanFormError.scanDate }}</span>
              </div>
            </div>

            <div class="row py-2">
              <div class="col-md-3">
                <div class="form-floating">
                  <input type="text" formControlName="patientName" class="form-control" placeholder="Patient Name">
                  <label>Patient Name <span style="color:red;">*</span></label>
                </div>
                <span *ngIf="scanFormError.patientName" class="text-danger">{{ scanFormError.patientName }}</span>
              </div>
              <div class="col-md-3">
                <div class="form-floating">
                  <input type="tel" formControlName="age" class="form-control" placeholder="Patient Age">
                  <label>Patient Age <span style="color:red;">*</span></label>
                </div>
                <span *ngIf="scanFormError.age" class="text-danger">{{ scanFormError.age }}</span>
              </div>
              <div class="col-md-3">
                <div class="form-floating">
                  <select class="form-control" id="employee_joining" formControlName="gender">
                    <option value="" selected>Select A Patient Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                  <label>Patient Gender <span style="color:red;">*</span></label>
                </div>
                <span *ngIf="scanFormError.gender" class="text-danger">{{ scanFormError.gender }}</span>
              </div>
              <div class="col-md-3">
                <div class="form-floating">
                  <select class="form-control" id="employee_joining" formControlName="scanType">
                    <option value="" selected>Select A Scan Type</option>
                    <option value="Arterial Doppler">Arterial Doppler</option>
                    <option value="Mammogram">Mammogram</option>
                    <option value="USG Abdomen">USG Abdomen</option>
                    <option value="USG Breast i Axilla">USG Breast i Axilla</option>
                  </select>
                  <label for="label-form">Scan Type <span style="color:red;">*</span></label>
                </div>
                <span *ngIf="scanFormError.scanType" class="text-danger">{{ scanFormError.scanType }}</span>
              </div>
            </div>

            <div class="row py-2">
              <div class="col-md-3">
                <div class="form-floating">
                  <input type="text" formControlName="scanDoctorFees" placeholder="Scan Doctor Fees"
                    class="form-control" (input)="onScanDoctor($event)">
                  <label>Scan Doctor Fees <span style="color:red;">*</span></label>
                </div>
                <span *ngIf="scanFormError.scanDoctorFees" class="text-danger">{{ scanFormError.scanDoctorFees }}</span>
              </div>
              <div class="col-md-3">
                <div class="form-floating">
                  <input type="text" formControlName="referenceDoctorFees" placeholder="Reference Doctor Fees"
                    class="form-control" (input)="onReferenceDoctor($event)">
                  <label>Reference Doctor Fees <span style="color:red;">*</span></label>
                </div>
                <span *ngIf="scanFormError.referenceDoctorFees" class="text-danger">{{ scanFormError.referenceDoctorFees
                  }}</span>
              </div>
              <div class="col-md-3">
                <div class="form-floating">
                  <input type="text" formControlName="nikithaScanFees" class="form-control"
                    placeholder="Nikhitha Scan Fees" (input)="onNikita($event)">
                  <label>Nikhitha Scan Fees <span style="color:red;">*</span></label>
                </div>
                <span *ngIf="scanFormError.nikithaScanFees" class="text-danger">{{ scanFormError.nikithaScanFees
                  }}</span>
              </div>
              <div class="col-md-3">
                <div class="form-floating">
                  <div class="form-floating">
                    <input type="tel" formControlName="totalScanAmount" placeholder="Total Scan Amount"
                      class="form-control" readonly>
                    <label>Total Scan Amount <span style="color:red;">*</span></label>
                  </div>
                  <span *ngIf="scanFormError.totalScanAmount" class="text-danger">{{ scanFormError.totalScanAmount
                    }}</span>
                </div>
              </div>
            </div>
            <div class="row text-center">
              <div class="col-md-12">
                <button type="submit" class="btn btn-primary btn-lg">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <app-amdds-footer></app-amdds-footer>

    <ngx-spinner>
      <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
      </div>
    </ngx-spinner>