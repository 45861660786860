import { Config } from "@/shared/main/Config";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export  class ConfigService {
    private config: Config | undefined = undefined;

    constructor(private http: HttpClient) {
    }

    load(url: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.http.get<Config>(url).subscribe({
                next: (config) => {
                    this.config = config;
                    resolve();
                },
                error: (error) => {
                    console.error("Error loading config:", error);
                    reject(error);
                }
            });
        });
    }
    

    getConfig(): Config {
        return this.config;
    }
}