import { Component, OnInit } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import * as CryptoJS from 'crypto-js';
import { LabTestDetails } from "@/model/labDetails/labTestDetails";

@Component({
  selector: "app-lab-receipt-print",
  templateUrl: "./lab-receipt-print.component.html",
  styleUrls: ["./lab-receipt-print.component.scss"]
})
export class LabReceiptPrintComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  labTestDetails : LabTestDetails;
  label : string;

  constructor(private route: ActivatedRoute,private router: Router,private datePipe: DatePipe) {
    
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const encryptedData = params['data'];
      this.label = params['label'];
      if (encryptedData) {
        this.labTestDetails = this.decryptData(encryptedData);
      }
    });
    setTimeout(function () {
      window.print();
    }, 3000);
    setTimeout(() => {
      if(this.label == undefined  || this.label == null || this.label == "") {
      this.router.navigate(["/lab-test-form"]);
      }else{
        this.router.navigate(["/lab-test-view"]);
      }
    }, 7000);
  }

  decryptData(encryptedData: string) {
    const bytes = CryptoJS.AES.decrypt(encryptedData, 'secret-key');
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }

  transformDate(date: string): string | null {
    return this.datePipe.transform(date, 'MM-dd-yyyy / hh:mm a');
  }
  

}
