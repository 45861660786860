<app-amdds-header></app-amdds-header>
<nav class="navbar navbar-expand-lg navbar-light mt-5" style="background-color:darkgray">
  <div class="container-fluid">
    <a class="navbar-brand">Scan Details</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mx-4 topnav" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto ">
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/scan-center"><b>Scan Entry</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/scan-center-payment"><b>Scan Payment Details</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/scan-center-doctor-payment"><b>Scan Doctor Payment</b></a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="container-fluid">
  <div class="row py-5"></div>
  <div class="row">
    <div class="col-md-6">
      <div class="row justify-content-center py-4">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form [formGroup]="scanPayment" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col-md-9"></div>
                  <div class="col-md-3">
                    <div class="form-floating">
                      <input type="date" formControlName="scanDate" class="form-control" [max]="currentDate"
                        (change)="onDate($event)">
                      <label>Date</label>
                    </div>
                  </div>
                </div>

                <div formArrayName="payments">
                  <div *ngFor="let payment of paymentsArray.controls; let i = index" [formGroupName]="i">
                    <div class="row py-2">
                      <div class="col-md-3">
                        <div class="form-floating">
                          <input type="tel" formControlName="scanFees" class="form-control"
                            [placeholder]="getLabel(payment.get('paymentType')?.value)" readonly>
                          <label>{{ paymentTypes[i]}} <span style="color:red;">*</span></label>
                          <span
                            *ngIf="payment.get('scanFees')?.invalid && (payment.get('scanFees')?.touched || payment.get('scanFees')?.dirty)"
                            class="text-danger">
                            Scan Fees is required
                          </span>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-floating">
                          <input type="date" formControlName="paidDate" class="form-control" placeholder="Paid Date">
                          <label>Paid Date <span style="color:red;">*</span></label>
                          <span
                            *ngIf="payment.get('paidDate')?.invalid && (payment.get('paidDate')?.touched || payment.get('paidDate')?.dirty)"
                            class="text-danger">
                            Paid Date is required
                          </span>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-floating">
                          <select class="form-control" formControlName="paymentType">
                            <option value="" selected>Select a Payment</option>
                            <option value="Bank Deposit">Bank Deposit</option>
                            <option value="On Hand">On Hand</option>
                          </select>
                          <label>Received Type <span style="color:red;">*</span></label>
                          <span
                            *ngIf="payment.get('paymentType')?.invalid && (payment.get('paymentType')?.touched || payment.get('paymentType')?.dirty)"
                            class="text-danger">
                            Payment Type is required
                          </span>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-floating">
                          <textarea formControlName="adminComments" class="form-control"
                            placeholder="Admin Comments"></textarea>
                          <label>Comments <span style="color:red;">*</span></label>
                          <span
                            *ngIf="payment.get('adminComments')?.invalid && (payment.get('adminComments')?.touched || payment.get('adminComments')?.dirty)"
                            class="text-danger">
                            Comments are required
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row text-center mt-2">
                  <div class="col-md-12">
                    <button type="submit" class="btn btn-primary btn-lg">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="container mt-1" *ngIf="onShowScanDetails">
        <div class="table-responsive">
          <table class="table">
            <thead class="rounded">
              <tr class="sticky-top">
                <th class="col-1">No</th>
                <th class="col-1">Patient Name</th>
                <th class="col-1">Patient Age</th>
                <th class="col-1">Scan Type</th>
                <th class="col-1">Scan Doctor Fees</th>
                <th class="col-1">References Doctor Fees</th>
                <th class="col-1">Nikhitha Scan Fees</th>
                <th class="col-4">Scan Date</th>
                <th class="col-2">Payment Status</th>
                <th class="col-1">print</th>
              </tr>
            </thead>
            <tbody *ngIf="this.getScanPaymentDetails.length > 0; else noDataFound">
              <tr *ngFor="let fees of getScanPaymentDetails| filter: searchText; let i = index">
                <td>{{i + 1}}</td>
                <td>{{fees.patientName}}</td>
                <td>{{fees.age}}</td>
                <td>{{fees.scanType}}</td>
                <td>{{fees.scanDoctorFees | currency:'INR':''}}</td>
                <td>{{fees.referenceDoctorFees | currency:'INR':''}}</td>
                <td>{{fees.nikithaScanFees | currency:'INR':''}}</td>
                <td>{{fees.createdAt | date: 'MM-dd-yyyy / hh:mm a'}}</td>
                <td><i class="fas fa-check" style="color: green;" *ngIf="fees.paymentStatus == 'Paid'"></i>
                  <button type="button" *ngIf="fees.paymentStatus != 'Paid' " (click)="UpdatePaymentStatus(fees.scanId)"
                    class="btn btn-danger btn-sm" [disabled]="disabledStatus[fees.scanId]"
                    [ngStyle]="{'color': disabledStatus[fees.scanId] ? 'white' : 'inherit', 'background-color': disabledStatus[fees.scanId] ? 'black' : 'primary'}">Not Paid</button>
                </td>
                <td><i class="fa fa-print" (click)="print(fees)"></i></td>
              </tr>
            </tbody>
            <ng-template #noDataFound>
              <tr>
                <td colspan="6" class="text-center">Data Not Found</td>
              </tr>
            </ng-template>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner>
  <div class="loader">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>

<app-amdds-footer></app-amdds-footer>