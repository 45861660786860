import { Component, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { Subject, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { ScanCenterDetails } from "@/model/scenCenterDetails";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { scanDoctorPayments } from "@/model/scanDoctorPayments";
import Swal from "sweetalert2";
import * as CryptoJS from 'crypto-js';
import { Router } from "@angular/router";

@Component({
  selector: "app-scan-center-payment",
  templateUrl: "./scan-center-payment.component.html",
  styleUrls: ["./scan-center-payment.component.scss"]
})
export class ScanCenterPaymentComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentDate: String;
  searchText: any;
  UniqueData: string = "";
  allGetScanDetails: ScanCenterDetails[] = [];
  getScanPaymentDetails: ScanCenterDetails[] = [];
  getDoctorPayment: scanDoctorPayments[] = [];
  getScanDoctorAmount: number = 0;
  getReferenceAmount: number = 0;
  getNikitaScanFees: number = 0;
  paymentTypes = ['Scan Doctor', 'Ref Doctor', 'Nikhitha Scan'];
  scanPayment: FormGroup;
  onShowScanDetails: boolean = false;
  onShowDoctorPayments: boolean = false;
  disabledStatus: { [labTestId: number]: boolean } = {};


  constructor(private apiService: AppService, private datePipe: DatePipe, private toastr: ToastrService,
    private spinner: NgxSpinnerService, private fb: FormBuilder,private router : Router) {
    this.scanPayment = this.fb.group({
      payments: this.fb.array([])
    });
    const currentDate = new Date();
    this.currentDate = this.datePipe.transform(currentDate, "yyyy-MM-dd") ?? "";
  }

  encryptData(data: any) {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret-key').toString();
    return encryptedData;
  }


  initializePaymentForm() {
    this.paymentTypes.forEach((type, index) => {
      (this.scanPayment.get('payments') as FormArray).push(
        this.fb.group({
          paymentType: [type, [Validators.required]],
          paidDate: ["", [Validators.required]],
          adminComments: ["", [Validators.required]],
          scanFees: ["", [Validators.required]],
          referenceType: [this.paymentTypes[index]],
          scanDate: [""]
        })
      );
    });
  }
  onSubmit() {
    this.formValidation();
  }

  formValidation() {
    if (this.scanPayment.valid) {
      this.saveScanPayment();
    } else {
      this.scanPayment.markAllAsTouched();
    }
  }

  ngOnInit(): void {
    this.onShowScanDetails = true;
    this.getAllScanDetails();
    this.initializePaymentForm();
  }


  onDate(event: any): void {
    this.UniqueData = event.target.value;
    if(this.UniqueData == ""){
      this.getAllScanDetails()
    }else {
      this.getDoctorPaymentDetails();
    }
  }

  getDoctorPaymentDetails() {
    this.apiService.getScanDoctorPaymentByDate(this.UniqueData)
     .pipe(takeUntil(this.destroy$))
     .subscribe(data => {
      const existingDetails = data;
      if(existingDetails.length == 0){
        this.scanPayment.get('payments').enable();
        this.getScanDetailsDate();
      }else{
        this.scanPayment.get('payments').disable();
        this.toastr.error("Already payment details saved");
      }
      }, (error: any) => {
        console.log(error.message);
      });
  }

  getAllScanDetails() {
    this.apiService.getAllScanDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.getScanPaymentDetails = data.sort((a, b) => b.scanId - a.scanId);;
        console.log(this.getScanPaymentDetails)
      }, (error: any) => {
        console.log(error.message);
      });
  }

  onSelectFunction(event: any): void {
    const value = event.target.value;
    if (value == "Scan Payment Details") {
      this.getScanDoctorPaymentDetails();
      this.onShowDoctorPayments = true;
      this.onShowScanDetails = false;
    } else {
      this.getAllScanDetails();
      this.onShowDoctorPayments = false;
      this.onShowScanDetails = true;
    }
  }

  getScanDoctorPaymentDetails() {
    this.apiService.getScanDoctorPayment()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.getDoctorPayment = data.sort((a, b) => b.doctorScanId - a.doctorScanId);
        console.log("testt", data)
      }, (error: any) => {
        console.log(error.message);
        this.toastr.error(error.massage)
      })
  }

  getScanDetailsDate() {
    this.apiService.getScanDetailsDateBased(this.UniqueData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.getScanPaymentDetails = data.sort((a, b) => b.scanId - a.scanId);
        this.getScanDoctorAmount = this.getScanPaymentDetails.reduce((sum, item) => sum + +item.scanDoctorFees, 0);
        this.getReferenceAmount = this.getScanPaymentDetails.reduce((sum, item) => sum + +item.referenceDoctorFees, 0);
        this.getNikitaScanFees = this.getScanPaymentDetails.reduce((sum, item) => sum + +item.nikithaScanFees, 0);

        this.paymentsArray.controls.forEach(control => {
          const paymentType = control.get('paymentType')?.value;
          if (paymentType === 'Scan Doctor') {
            control.get('scanFees')?.setValue(this.getScanDoctorAmount);
          } else if (paymentType === 'Ref Doctor') {
            control.get('scanFees')?.setValue(this.getReferenceAmount);
          } else if (paymentType === 'Nikhitha Scan') {
            control.get('scanFees')?.setValue(this.getNikitaScanFees);
          }
        });
      }, (error: any) => {
        console.log(error.message);
      });
  }

  get paymentsArray() {
    return this.scanPayment.get('payments') as FormArray;
  }

  getLabel(paymentType: string): string {
    return `${paymentType} Amount`;
  }

  saveScanPayment() {
    const paymentsArray = this.scanPayment.get('payments') as FormArray;
    const paymentDetails = paymentsArray.controls.map(control => {
      return {
        scanDate: this.UniqueData,
        paidDate: control.get('paidDate')?.value,
        paymentType: control.get('paymentType')?.value,
        adminComments: control.get('adminComments')?.value,
        scanFees: control.get('scanFees')?.value,
        referenceType: control.get('referenceType')?.value,
      };
    });
    this.apiService.saveScanPaymentDetails(paymentDetails)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        response => {
          this.spinner.hide();
        this.successAlert();
        },
        (error: any) => {
          this.toastr.error("Something went wrong, Please try again later");
          console.log(error.message);
        }
      );
  }

  UpdatePaymentStatus(labTestId: number) {
    const value = labTestId
    for (let up of this.getScanPaymentDetails) {
      if (value == up.scanId) {
        this.disabledStatus[value] = true;
        const jsonData = {
          scanId: value,
          paymentStatus: "Paid"
        }
        this.updateScanPaymentStatus(jsonData);
      }
    }
  }

  updateScanPaymentStatus(jsonData : any) {
    this.spinner.show();
    this.apiService
      .updateScanPaymentStatus(jsonData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
      }, (err: any) => {
        console.log("Something Went Wrong")
        this.spinner.hide();
        this.toastr.error("Something went wrong , Please try again later....")
      });
  }
  

  print(scanDetails : ScanCenterDetails){
    const encryptedData = this.encryptData(scanDetails);
    this.router.navigate(['/scan-center-print', { data: encryptedData , label : 'scan-details'}]);
  }
  


  successAlert() {
    Swal.fire({
      title: "Payment details saved successfully",
      confirmButtonText: "Ok",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload()
      }
    });
  }

}
