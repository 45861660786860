import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-amdds-dashboard',
  templateUrl: './marg.component.html',
  styleUrls: ['./marg.component.scss']
})
export class MargComponent {
  userDisable = false;
  labDisable = false;
  adminDisable = false;
  scanDisable = false;

  constructor(private router: Router) { }

}
