<app-amdds-header></app-amdds-header>
<!--<nav class="navbar navbar-expand-lg navbar-light mt-5" style="background-color:darkgray">
  <div class="container-fluid">
    <a class="navbar-brand">Anitha Medical</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mx-4 topnav" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto ">
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/add-product"><b>Add Product</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/product-entry"><b>Product Purchase Entry</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/product-report"><b>Product Report</b></a>
        </li>
      </ul>
    </div>
  </div>
</nav>-->

<div class="container-fluid mt-5">
  <div class="row py-2"></div>

  <section>
    <div class="row d-flex flex-wrap gap-2 align-items-center">
      <div class="col-12 col-lg-auto">
        <div class="form-floating">
          <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
            (change)="updateDetails($event,'name')" [(ngModel)]="productName">
            <option selected disabled value="">Select Product Name</option>
            <option value="all">All</option>
            <option *ngFor="let name of this.productNameDetails">{{name}}</option>
          </select>
          <label for="floatingSelect">Product Name</label>
        </div>
      </div>

      <div class="col-12 col-lg-auto">
        <div class="form-floating">
          <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
            (change)="updateDetails($event,'type')" [(ngModel)]="productType">
            <option selected disabled value="">Select Product Type</option>
            <option value="all">All</option>
            <option *ngFor="let type of this.productTypeDetails">{{type}}</option>
          </select>
          <label for="floatingSelect">Product Type</label>
        </div>
      </div>

      <div class="col-12 col-lg-auto">
        <div class="form-floating">
          <input type="search" class="form-control" placeholder="search" [(ngModel)]="searchText">
          <label class="text-gray">Search</label>
        </div>
      </div>

      <div class="col-12 col-lg-auto">
        <button class="btn btn-danger" (click)="resetReviewFilter()">
          Clear Filters <i class="bi bi-x-octagon"></i>
        </button>
      </div>

      <div class="col-12 col-lg-auto justify-content-end">
        <button class="btn btn-success" (click)="downloadExcel()">
          Download Excel <i class="bi bi-file-earmark-spreadsheet"></i>
        </button>
      </div>
    </div>

  </section>

  <div class="row mt-3">
    <div class="col-md-12 table-responsive-md table-wrapper-scroll-y tableFixHead">
      <table class="table table-sm table-bordered text-center table-hover mb-0">
        <thead class="header sticky-top">
          <tr>
            <th>S.NO</th>
            <th>Product Name</th>
            <th>Product Type</th>
            <th>Total Count</th>
            <th>List Of Details</th>
          </tr>
        </thead>
        <tbody *ngIf="this.getProductDetails.length > 0; else noDataFound">
          <tr *ngFor="let table of this.getProductDetails; let i=index">
            <td> {{ i+1 }} </td>
            <td>{{ table.name}}</td>
            <td>{{ table.type }}</td>
            <td>{{ table.productAvailQty }}</td>
            <td>
              <button type="button" class="btn btn-primary btn-sm rounded-pill"
                (click)="viewDetails(table.productId,table.name)">View Details
                <i class="bi bi-eye"></i></button>
            </td>
          </tr>
        </tbody>
        <ng-template #noDataFound>
          <tr>
            <td colspan="3">Data Not Found</td>
          </tr>
        </ng-template>
      </table>
    </div>
  </div>
</div>


<div class="modal" id="myModal" [style.display]="isViewShow ? 'block' : 'none'">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"> <b>Medicine Name Based Stock Details</b> </h5>
        <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5># {{this.selectedProductName}}</h5>
        <div class="table-responsive p-0 text-center" style="height: 350px;overflow-y: scroll;">

          <table class="table">
            <thead style="background-color: #4188F9;">
              <tr class="sticky-top">
                <th>S.No</th>
                <th>Batch Code</th>
                <th>Total Quantity</th>
                <th>Expiry Date</th>
                <th>Stock Upload Date</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody *ngFor="let dec of allStockDetails; let i = index">
              <tr>
                <td>{{i+1}}</td>
                <td>{{dec.batchCode}}</td>
                <td>{{dec.purchaseQty}}</td>
                <td>{{dec.expiryDate}}</td>
                <td>{{dec.createdAt | date:'dd-MM-yyyy' }}</td>
                <td>
                  <button type="button" class="btn btn-primary btn-sm rounded-pill"
                    (click)="editStock(dec.productBatchId)">Edit
                    <i class="bi bi-pencil-square"></i></button>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="close()">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>


<app-amdds-footer></app-amdds-footer>
